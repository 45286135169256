import * as React from 'react';
import OurTeamSection from './ourTeamSection';
import CoreValuesSection from './coreValuesSection';
import OfficePhotosSection from './officePhotosSection';
import OurMissionSection from './ourMissionSection';
import NavBar from '../navBar';
import Footer from '../footer';

interface Props {
	openSignUpForm: () => void;
}

class AboutPage extends React.Component<Props, any> {

	constructor(props: any) {
		super(props);
		this.state = {
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}




	render() {

		const { isShow, contact } = this.state;
		const { openSignUpForm } = this.props;

		return (
			<div>
				{/* {!isShow ? */}
				<div>
					<div className="about-page-container">
						<div className="navbar-hero-container">
							<NavBar location="about-page" openSignUpForm={openSignUpForm} />
							<div className="about-page-hero-container">
								<div className="hero-text-container">
									<h1>Not your ordinary</h1>
									<h1>title company</h1>
								</div>
							</div>
						</div>
						<OurMissionSection />
						<OfficePhotosSection />
						<CoreValuesSection />
						<div className="about-team-photo" />
						<OurTeamSection />
					</div>
					<Footer />
				</div>
			</div>
		);
	}
}

export default AboutPage;