import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';

interface State {
    elementVisible: boolean;
}

class OfficePhotosSection extends React.Component<any, State> {

    state = {
        elementVisible: false,
    }

    handleVisibilityUpdate = (e: any, data: any) => {

        const { elementVisible } = this.state;
        const { topVisible, bottomVisible } = data.calculations;

        if (!elementVisible && (topVisible || bottomVisible)) {
            this.setState({
                elementVisible: true,
            })
        }
    }

    render() {

        const { elementVisible } = this.state;

        return (
            <div className="office-photos-section-container">
                <div className="office-photos-content">
                    <div className="tan-background-container">
                        <div className="tan-background-block" />
                        <div className="white-space-buffer" />
                    </div>
                    <Visibility fireOnMount onUpdate={this.handleVisibilityUpdate} className="visibility-photo-container" >
                        <div className="office-photos-container" >
                            <iframe width="100%" height="80%"
                                src="https://www.youtube.com/embed/RvHrc_QhVVQ">
                            </iframe>
                        </div>
                    </Visibility>
                </div>
            </div>
        );
    }
}

export default OfficePhotosSection;