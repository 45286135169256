import * as React from 'react';
import ServicesCarousel from './servicesCarousel';
import { Link } from 'react-router-dom';
import { Visibility, Transition } from 'semantic-ui-react';
import { NavHashLink as NavLink } from 'react-router-hash-link';

interface State {
	elementVisible: boolean;
}

class ServicesSection extends React.Component<any, State> {
	state = {
		elementVisible: false
	};

	handleVisibilityUpdate = (e: any, data: any) => {
		const { elementVisible } = this.state;
		const { topVisible, bottomVisible } = data.calculations;

		if (!elementVisible && (topVisible || bottomVisible)) {
			this.setState({
				elementVisible: true,
			});
		}
	};

	render() {

		const { elementVisible } = this.state;

		return (
            <Visibility fireOnMount onUpdate={this.handleVisibilityUpdate} className="homepage-visibility-services-container">
				<div className="homepage-services-container">
					<div className="services-background-full-transition">
						<div className="services-text-content-container">
							<Transition visible={elementVisible} animation="fade up" duration={2500}>
								<div className="services-text-content">
									<div className="services-title-container">
										<h2 id="services-title" className="services-bigger-title">Services</h2>
										<h2>you can count on</h2>
									</div>
									<div className="services-paragraph-container">
										<p>We’ve made a commitment to support our clients at every opportunity. At the core of our business is our people.  Passionate and professional, we employ some of the best and brightest in the industry coupled with the best technology. We continue to develop new solutions, uncover fresh opportunities and build the trusted company of tomorrow.</p>
									</div>
									<div className="services-description-container">
										<div className="service-description-card">
											<div className="service-description-title">Title Insurance</div>
											<p className="service-description-paragraph">Our relationships with our underwriters will keep one of your most valuable assets secure with the highest of financial ratings. You should TRULY expect the best when dealing with us.</p>
											<NavLink to="/services#services-title-insurance" scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -64) }}>
												<p className="paragraph-end-link">More…</p>
											</NavLink>
										</div>
										<div className="description-card-divider" />
										<div className="service-description-card" id="description-card-right">
											<div className="service-description-title">Closing/Escrow Services</div>
											<p className="service-description-paragraph">Escrow is a collaboration of all parties to execute a real estate transaction timely and efficiently. Our highly trained, licensed and talented staff is ready to provide TRULY exceptional service to our clients.</p>
											<NavLink to="/services#closing-services" scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -64) }}>
												<p className="paragraph-end-link">More… </p>
											</NavLink>
										</div>
									</div>
									<div className="services-carousel-button-container">
										<ServicesCarousel />
										<div className="discover-btn-container">
											<Link to="/services">
												<div className="services-discover-button">
													DISCOVER
												</div>
											</Link>
										</div>
									</div>	
								</div>
							</Transition>
						</div>
					</div>
					<div className="services-background-full-image" />									
				</div>
			</Visibility>

		);
	}
}

export default ServicesSection;