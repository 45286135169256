
import * as React from 'react';
import { DivisionType, ResourceType, Resources, Resource, resourceOptions, ResourceGroup } from '../../types/resources';
import ResourceCard from './resourceCard';
import ScrollContainer from 'react-indiana-drag-scroll';

interface Props {
    division: DivisionType;
    resource: ResourceType
}

const ResourceSection = ({ resourceType, resources, divisionType }: ResourceGroup) => {
   

    return (
        <div className='resource-section'>
            <div className='title'>{resourceType} <span className='doc-count'>({resources.length})</span></div>
            {
                resources.length ?
                    <div className='card-container'>
                        {resources.map((resource: Resource) => <ResourceCard resource={resource} key={resource.key} />)}
                    </div>
                    :
                    <div className='sub-title' id='no-docs-desktop'>There are no documents specific to {divisionType} available. Try changing the filter to All States.</div> 
            }
            {
                resources.length ?
                    <ScrollContainer horizontal={true} nativeMobileScroll={true} className='indiana-scroll'>
                        {resources.map((resource: Resource) => <ResourceCard resource={resource} key={resource.key} />)}
                    </ScrollContainer>
                    :
                    <div className='sub-title' id='no-docs-mobile'>There are no documents available for this category.</div>
            }
        </div>
    );
}

class ResourcesDocs extends React.Component<Props> {

    render() {

        const { resource, division } = this.props;        

        // first filter by division
        let filteredResources = Resources.filter((x: Resource) => {
            return division === 'All States' ? true : x.divisionType === division;
        });

        // organize them into groups by resource type (excluding All Resources)
        const groupOptions = resourceOptions.filter((x: ResourceType) => {
            if (resource === 'All Resources') return x !== 'All Resources';
            return x === resource;
        });
        const resourceGroups: ResourceGroup[] = groupOptions.map((resourceType: ResourceType) => {
            return {
                resourceType,
                resources: filteredResources.filter((x: Resource) => x.resourceType === resourceType),
                divisionType: division
            };
        });

        console.log('resourceGroups: ', resourceGroups);

        return (
            <div className='width-container mob-full'>
                <div className='resource-docs-container'>
                    {resourceGroups.map((x: ResourceGroup) => (
                        <ResourceSection
                            key={`resource-section-${x.resourceType}`}
                            resourceType={x.resourceType}
                            resources={x.resources}
                            divisionType={x.divisionType}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export default ResourcesDocs;