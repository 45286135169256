import * as React from 'react';
import { Modal, Button, Header, Icon } from 'semantic-ui-react';

interface Props {
	showErrorModal: boolean;
	closeContactForm: () => void;
}

class ErrorModal extends React.Component<Props> {
	render() {

		const { showErrorModal, closeContactForm } = this.props;

		return (
			<Modal open={showErrorModal} size='small' dimmer="blurring" >
				<Header icon='warning' content='Unable to send message' />
				<Modal.Content id="message-content-container">
					<h3>Opps, something went wrong. We are looking into the issue. Please try again later.</h3>
				</Modal.Content>
				<Modal.Actions className="actions-button-container" id="actions-button-container">
					<div className="close-button" onClick={closeContactForm}>Got it</div>
				</Modal.Actions>
			</Modal>
		);
	}
}

export default ErrorModal;