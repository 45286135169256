import * as React from "react";
import ErrorModal from "../errorModal";
import { Visibility, Transition, Dimmer, Loader } from "semantic-ui-react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import { callEmailHandler } from "../../utilities/apiService";
import { contactEmailBody } from "./utils";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { red, deepOrange } from "@material-ui/core/colors";
import appConfig from "../../appConfig";

const topics = [
  {
    value: "I’m interested in joining the Truly Team",
    label: "I’m interested in joining the Truly Team",
  },
  {
    value: "General question or comment",
    label: "General question or comment",
  },
  {
    value: "Website or other feedback",
    label: "Website or other feedback",
  },
  {
    value: "Other",
    label: "Other",
  },
];

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      width: "100%",
      [theme.breakpoints.between("sm", "md")]: {
        width: "100%",
      },

      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },

    inputTextFields: {
      width: "40%",
      marginRight: "95px",
      [theme.breakpoints.between("sm", "md")]: {
        width: "100%",
        marginBottom: "5%",
      },

      [theme.breakpoints.down("xs")]: {
        width: "100%",
        marginTop: "-20px",
        marginBottom: "50px",
      },
    },

    cssLabel: {
      "&$cssFocused": {
        color: "#969696",
        fontSize: 14,
      },
      fontSize: "18px",
      position: "absolute",
      top: "-5px",
      fontFamily: "Proxima-nova, sans-serif",
    },
    cssFocused: {},

    cssOutlinedInput: {
      "&$cssFocused $notchedOutline": {
        borderColor: "F6490D",
      },
    },
    notchedOutline: {},
  });

const theme = createMuiTheme({
  palette: {
    primary: deepOrange,
    error: red,
  },
  // typography: { useNextVariants: true },
});

export interface Contact {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  topic: string;
  message: string;
}

interface State {
  [key: string]: string | boolean | {};
  contact: Contact;
  elementVisible: boolean;
  isValidEmail: boolean;
  isPhoneValid: boolean;
  firstNameComplete: boolean;
  lastNameComplete: boolean;
  emailComplete: boolean;
  showSubmission: boolean;
  showErrorModal: boolean;
  submitButtonEnabled: boolean;
}
export interface Props extends WithStyles<typeof styles> {}

class ContactUsPageComtainer extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      contact: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        topic: "",
        message: "",
      },
      elementVisible: false,
      isValidEmail: true,
      isPhoneValid: true,
      firstNameComplete: true,
      lastNameComplete: true,
      emailComplete: true,
      showSubmission: false,
      showErrorModal: false,
      submitButtonEnabled: true,
    };

    this.firstOnChange = this.firstOnChange.bind(this);
    this.lastOnChange = this.lastOnChange.bind(this);
    this.emailOnChange = this.emailOnChange.bind(this);
    this.phoneOnChange = this.phoneOnChange.bind(this);
    this.topicOnChange = this.topicOnChange.bind(this);
    this.messageOnChange = this.messageOnChange.bind(this);
    this.handleVisibilityUpdate = this.handleVisibilityUpdate.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const search = location.search;
    const searchParams = new URLSearchParams(search);
    const email = searchParams.get("email");
    const topic = searchParams.get("topic");
    this.setState({
      contact: {
        ...this.state.contact,
        email: email ? (this.validateEmail(email) ? email : email) : "",
        topic: topic || "",
      },
      isValidEmail: email ? (this.validateEmail(email) ? true : false) : true,
    });
    window.scrollTo(0, 0);
  }

  firstOnChange(e: any) {
    this.setState({
      contact: {
        firstName: e.target.value,
        lastName: this.state.contact.lastName,
        email: this.state.contact.email,
        message: this.state.contact.message,
        phone: this.state.contact.phone,
        topic: this.state.contact.topic,
      },
    });
  }

  lastOnChange(e: any) {
    this.setState({
      contact: {
        firstName: this.state.contact.firstName,
        lastName: e.target.value,
        email: this.state.contact.email,
        message: this.state.contact.message,
        phone: this.state.contact.phone,
        topic: this.state.contact.topic,
      },
    });
  }

  emailOnChange(e: any) {
    this.setState(
      {
        contact: {
          firstName: this.state.contact.firstName,
          lastName: this.state.contact.lastName,
          email: e.target.value,
          message: this.state.contact.message,
          phone: this.state.contact.phone,
          topic: this.state.contact.topic,
        },
      },
      () => {
        if (/^\S+@\S+$/.test(this.state.contact.email)) {
          this.setState({ isValidEmail: true });
        } else {
          this.setState({ isValidEmail: false });
        }
      }
    );
  }

  phoneOnChange(e: any) {
    this.setState(
      {
        contact: {
          firstName: this.state.contact.firstName,
          lastName: this.state.contact.lastName,
          email: this.state.contact.email,
          message: this.state.contact.message,
          phone: e.target.value,
          topic: this.state.contact.topic,
        },
      },
      () => {
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (this.state.contact.phone.match(phoneno)) {
          this.setState({ isPhoneValid: true });
        } else {
          this.setState({ isPhoneValid: false });
        }
      }
    );
  }

  topicOnChange(e: any) {
    this.setState({
      contact: {
        firstName: this.state.contact.firstName,
        lastName: this.state.contact.lastName,
        email: this.state.contact.email,
        message: this.state.contact.message,
        phone: this.state.contact.phone,
        topic: e.target.value,
      },
    });
  }

  messageOnChange(e: any) {
    this.setState({
      contact: {
        firstName: this.state.contact.firstName,
        lastName: this.state.contact.lastName,
        email: this.state.contact.email,
        message: e.target.value,
        phone: this.state.contact.phone,
        topic: this.state.contact.topic,
      },
    });
  }

  handleVisibilityUpdate = (e: any, data: any) => {
    const { elementVisible } = this.state;
    const { topVisible, bottomVisible } = data.calculations;

    if (!elementVisible && (topVisible || bottomVisible)) {
      this.setState({ elementVisible: true });
    }
  };

  submitForm() {
    if (
      this.state.contact.firstName == "" &&
      this.state.contact.lastName == "" &&
      this.state.contact.email == ""
    ) {
      this.setState({
        firstNameComplete: false,
        lastNameComplete: false,
        emailComplete: false,
      });
    }

    if (
      this.state.contact.firstName !== "" &&
      this.state.contact.lastName == "" &&
      this.state.contact.email == ""
    ) {
      this.setState({
        firstNameComplete: true,
        lastNameComplete: false,
        emailComplete: false,
      });
    }

    if (
      this.state.contact.firstName == "" &&
      this.state.contact.lastName !== "" &&
      this.state.contact.email == ""
    ) {
      this.setState({
        firstNameComplete: false,
        lastNameComplete: true,
        emailComplete: false,
      });
    }

    if (
      this.state.contact.firstName == "" &&
      this.state.contact.lastName == "" &&
      this.state.contact.email !== ""
    ) {
      this.setState({
        firstNameComplete: false,
        lastNameComplete: false,
        emailComplete: true,
      });
    }

    if (
      this.state.contact.firstName !== "" &&
      this.state.contact.lastName !== "" &&
      this.state.contact.email == ""
    ) {
      this.setState({
        firstNameComplete: true,
        lastNameComplete: true,
        emailComplete: false,
      });
    }

    if (
      this.state.contact.firstName !== "" &&
      this.state.contact.lastName == "" &&
      this.state.contact.email !== ""
    ) {
      this.setState({
        firstNameComplete: true,
        lastNameComplete: false,
        emailComplete: true,
      });
    }

    if (
      this.state.contact.firstName == "" &&
      this.state.contact.lastName !== "" &&
      this.state.contact.email !== ""
    ) {
      this.setState({
        firstNameComplete: false,
        lastNameComplete: true,
        emailComplete: true,
      });
    }

    if (
      this.state.contact.firstName !== "" &&
      this.state.contact.lastName !== "" &&
      this.state.contact.email !== "" &&
      this.state.isValidEmail
    ) {
      if (this.state.submitButtonEnabled) {
        this.setState(
          {
            firstNameComplete: true,
            lastNameComplete: true,
            emailComplete: true,
            submitButtonEnabled: false,
          },
          () => {
            const recipients = ["troole@trulytitle.com"];

            callEmailHandler({
              to: recipients,
              subject: "Contact Us submission from TrulyTitle.com",
              text: contactEmailBody(this.state.contact),
            })
              .then((response) => {
                if (response.ok) {
                  setTimeout(() => {
                    this.setState({ showSubmission: true });
                  }, 1500);
                } else {
                  setTimeout(() => {
                    this.setState({ showErrorModal: true });
                  }, 1500);
                }
              })
              .catch((data) => {
                setTimeout(() => {
                  this.setState({ showErrorModal: true });
                }, 1500);
              });
          }
        );
      }
    }
  }
  renderBack = () => {
    this.props.history.goBack();
  };

  closeErrorModal = () => {
    this.setState({ showErrorModal: false, submitButtonEnabled: true });
  };

  renderForm = () => {
    const { classes, closeContactForm } = this.props;
    const { showErrorModal, submitButtonEnabled } = this.state;

    return (
      <div>
        <input onChange={(event) => {}} />
        <div className="contact-us-body-container">
          <div className="contact-us-sections-container">
            <div>
              <div className="contact-us-header">
                <div className="heading-contactus">Contact Us</div>
                <div className="close-contact" onClick={this.renderBack}>
                  x CLOSE
                </div>
              </div>
              <div className="contact-form-body">
                <div className="form-sections">
                  <div className="inputs-fields">
                    <div className="fields-main">
                      <MuiThemeProvider theme={theme}>
                        <TextField
                          className={classes.inputTextFields}
                          label="First "
                          required
                          id="first"
                          InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused,
                            },
                          }}
                          onChange={this.firstOnChange}
                          error={!this.state.firstNameComplete}
                        />
                      </MuiThemeProvider>

                      <MuiThemeProvider theme={theme}>
                        <TextField
                          className={classes.inputTextFields}
                          label="Last"
                          id="last"
                          required
                          InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused,
                            },
                          }}
                          value={this.state.contact.lastName}
                          onChange={this.lastOnChange}
                          error={!this.state.lastNameComplete}
                        />
                      </MuiThemeProvider>
                    </div>

                    <div className="fields-main">
                      <MuiThemeProvider theme={theme}>
                        <TextField
                          className={classes.inputTextFields}
                          label="Email "
                          type="email"
                          required
                          id="email"
                          InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused,
                            },
                          }}
                          error={
                            !this.state.isValidEmail ||
                            !this.state.emailComplete
                          }
                          value={this.state.contact.email}
                          onChange={this.emailOnChange}
                        />
                      </MuiThemeProvider>

                      <MuiThemeProvider theme={theme}>
                        <TextField
                          className={classes.inputTextFields}
                          label="Phone"
                          id="phone"
                          type="phone"
                          InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused,
                            },
                          }}
                          value={this.state.contact.phone}
                          onChange={this.phoneOnChange}
                          error={!this.state.isPhoneValid}
                        />
                      </MuiThemeProvider>
                    </div>

                    <div className="fields-main-select">
                      <MuiThemeProvider theme={theme}>
                        <TextField
                          id="standard-select-topic"
                          select
                          label="Select Topic"
                          className={classes.textField}
                          value={this.state.contact.topic}
                          onChange={this.topicOnChange}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused,
                            },
                          }}
                          margin="normal"
                        >
                          {topics.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </MuiThemeProvider>
                    </div>

                    <div className="fields-main-text-area">
                      <MuiThemeProvider theme={theme}>
                        <TextField
                          id="message"
                          label="Message"
                          multiline
                          rows="8"
                          value={this.state.contact.message}
                          onChange={this.messageOnChange}
                          className={classes.textField}
                          margin="normal"
                          InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused,
                            },
                          }}
                        />
                      </MuiThemeProvider>
                    </div>

                    <div className="fields-main-send-button">
                      <div
                        className="contact-send"
                        onClick={() => {
                          this.submitForm();
                        }}
                      >
                        {submitButtonEnabled ? (
                          "SEND IT"
                        ) : (
                          <Dimmer className="light-tan-dimmer" inverted active>
                            <Loader>Processing...</Loader>
                          </Dimmer>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-contacts">
                    <div className="contact-card-text">
                      <div className="contact-icon" />
                      <h3 className="contact-title">{`General Contacts`}</h3>
                      <div className="contact-infos">
                        <div className="nm">Inquiries: </div>
                        <a href="mailto: info@trulytitle.com" className="eml">
                          info@trulytitle.com
                        </a>
                      </div>
                      <div className="contact-infos">
                        <div className="nm">Careers: </div>
                        <a href="mailto: troole@trulytitle.com" className="eml">
                          troole@trulytitle.com
                        </a>
                      </div>
                      <div className="contact-infos">
                        <div className="nm">Media: </div>
                        <a href="mailto: press@trulytitle.com" className="eml">
                          press@trulytitle.com
                        </a>
                      </div>
                      <div className="contact-infos">
                        <div className="nm">Support: </div>
                        <a href="mailto: help@trulytitle.com" className="eml">
                          help@trulytitle.com
                        </a>
                      </div>
                    </div>
                    <div className="social-icn">
                      <a
                        href="https://www.instagram.com/trulytitle/"
                        target="_blank"
                        className="icon-insta"
                      ></a>
                      <a
                        href="https://www.facebook.com/trulytitle"
                        target="_blank"
                        className="icon-fb"
                      ></a>
                      <a
                        href="https://www.linkedin.com/company/truly-title-inc/"
                        target="_blank"
                        className="icon-likedin"
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ErrorModal
          showErrorModal={showErrorModal}
          closeContactForm={this.closeErrorModal}
        />
      </div>
    );
  };

  renderSubmissionMessage = () => {
    const { closeContactForm } = this.props;

    return (
      <div className="contact-us-body-container" id="submission-body-container">
        <div
          className="contact-us-sections-container"
          id="submission-sections-container"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="contact-us-header" id="submission-header-container">
              <div className="heading-contactus" id="submission-header">
                Message Sent
              </div>
              <div
                className="close-contact"
                id="submission-close-contact"
                onClick={this.renderBack}
              >
                x CLOSE
              </div>
            </div>
            <div
              className="contact-form-body"
              id="submission-content-container"
            >
              <div className="msg-body-card" id="submission-body-card">
                <div className="msg-bdy" id="submission-body-content">
                  <div className="thank-you-us-txt">
                    Thank you for contacting us.{" "}
                  </div>
                  <div className="message-text-reach">
                    Someone from the Truly Title will reach out soon.
                  </div>
                  <div
                    className="close-submission-form"
                    onClick={this.renderBack}
                  >
                    CLOSE
                  </div>
                </div>
                <div className="submission-img"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  validateEmail = (email: string) => {
    return /^\S+@\S+$/.test(email) ? true : false;
  };

  render() {
    const { classes, closeContactForm } = this.props;
    const { elementVisible } = this.state;

    return (
      <Visibility
        fireOnMount
        onUpdate={this.handleVisibilityUpdate}
        className="visibility-location-container"
      >
        <Transition visible={elementVisible} animation="fade" duration={2500}>
          <div className="contact-us-page-container">
            {!this.state.showSubmission
              ? this.renderForm()
              : this.renderSubmissionMessage()}
          </div>
        </Transition>
      </Visibility>
    );
  }
}

export default withStyles(styles)(ContactUsPageComtainer);
