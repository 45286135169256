import * as React from 'react';
import NavBar from '../navBar';
import InnovativeTechSection from './innovativeTechSection';
import IntegratedPlatformSection from './integratedPlatformSection';
import InfrastructureSection from './infrastructureSection';
import SupportiveTechSection from './supportiveTechSection';
import Footer from '../footer';
import { NavLink } from 'react-router-dom';

interface Props {
	openSignUpForm: () => void;
}

class TechnologyPage extends React.Component<Props, any> {

	constructor(props: any) {
		super(props);

	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}



	render() {

		const { openSignUpForm } = this.props;

		return (
			<div>
				<div>
					<div className="tech-page-container" >
						<div className="navbar-hero-container">
							<NavBar location="tech-page" openSignUpForm={openSignUpForm} />
							<div className="tech-page-hero-container">
								<div className="hero-text-container">
									<h1>Technology</h1>
									<h1 className="title-lighter">meets efficiency</h1>
								</div>
							</div>
						</div>
						<InnovativeTechSection />
						<IntegratedPlatformSection />
						<InfrastructureSection />
						<SupportiveTechSection />
						<div className="sign-up-container">
							<div className="sign-up-content">
								<h2 className="title-bolder">Start</h2>
								<h2>working with Truly today!</h2>
								<NavLink to="/signup" className="btn btn-sign-up btn-red" onClick={openSignUpForm}>SIGN UP FOR FLUEID PRO</NavLink>
							</div>
						</div>
					</div >
					<Footer />
				</div >

			</div>
		);
	}
}

export default TechnologyPage;