import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';

interface State {
	elementVisible: boolean;
}

class InfrastructureSection extends React.Component<any, State> {

	state = {
		elementVisible: false
	}

	handleUpdate = (e: any, data: any) => {

		const { elementVisible } = this.state;
		const { topVisible, bottomVisible } = data.calculations;

		if (!elementVisible && (topVisible || bottomVisible)) {
			this.setState({ elementVisible: true });
		}
	}

	render() {

		const { elementVisible } = this.state;

		return (
			<Visibility fireOnMount onUpdate={this.handleUpdate}>
				<div className="infrastructure-container" id="infrastructure-container">
					<div className="infrastructure-content">
						<div className="background-container">
							<div className="content">
								<div className="visibility-img-container">
									<Transition visible={elementVisible} animation="fade up" duration={2500}>
										<div className="img-container"/>															
									</Transition>
								</div>			
								<div className="text-container">
									<div className="text-content">
										<h2>Secure</h2>
										<h2 className="title-bolder">Infrastructure</h2>
										<p>In today’s environment, knowing that your information is safe and secure is our number one priority. We work with the best in the technology industry to secure our data and that of our clients. We work around the clock building an environment that is secure, compliant and scalable so that our clients can rest assured that their data is TRULY safe.</p>
									</div>							
								</div>
							</div>
						</div>
					</div>					
				</div>
			</Visibility>
		);
	}
}

export default InfrastructureSection;