import * as React from 'react';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { NavHashLink as NavLink } from 'react-router-hash-link';

export const CookieBanner: React.SFC<any> = () => {
	return (
		<CookieConsent
			cookieName="truly-title-consent"
			cookieValue="true"
			
			disableStyles={true}
			containerClasses="cookie-container"
			contentClasses="cookie-content"
			buttonClasses="cookie-btn"
			buttonText="Accept"
		>
			We use cookies to offer you a better experience and analyze site traffic. By continuing to use this website, you consent to the use of cookies per our <NavLink to="/privacy#cookies" scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -64) }}>Cookie Policy</NavLink>
		</CookieConsent>
	);
}