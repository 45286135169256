import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';

interface State {
	elementVisible: boolean;
}

class OffersCardSection extends React.Component<any, State> {

    state = {
       elementVisible: false
    }

    handleVisibilityUpdate = (e: any, data: any) => {

		const { elementVisible } = this.state;
		const { topVisible, bottomVisible } = data.calculations;

		if (!elementVisible && (topVisible || bottomVisible)) {
			this.setState({ elementVisible: true })
		}
    }

    render() {

		const { elementVisible } = this.state;

		return (
            <div className="offers-card-container" >
                <div>
                    <Visibility fireOnMount onUpdate={this.handleVisibilityUpdate}>
                        <Transition visible={elementVisible} animation="fade up" duration={2500}>
                            <div className="title-products-we-of">
                                <div className="tech-card-flex-display">
                                    <div className="tech-card-content">
                                        <div className="tech-product-tools-icon" />
                                        <h3>TITLE PRODUCTS WE OFFER</h3>
                                        <ul>
											<li>Real Estate Closings</li>
											<li>Full ALTA Title Insurance</li>
											<li>ALTA Lender</li>
											<li>ALTA Residential Junior Loan Policy</li>
											<li>Commercial </li>
											<li>Builder</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </Visibility>
                </div>
                <div>
                    <Visibility onUpdate={this.handleVisibilityUpdate}>
                        <Transition visible={elementVisible} animation="fade up" duration={2500}>
                            <div className="title-services-we-of">
                                <div className="tech-card-flex-display">
                                    <div className="tech-card-content">
                                        <div className="tech-services-tools-icon" />
                                        <h3>TITLE SERVICES WE OFFER</h3>
                                        <ul>
											<li>Analytic Data</li>
											<li>Centralized Recording Services</li>
											<li>Commercial Services</li>
											<li>Builder Services</li>
											<li>Tax Information Services</li>
											<li>Deed and Property Reports</li>											
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </Visibility>
                </div>
            </div>
        );
    }
}

export default OffersCardSection;