import * as React from 'react';
import { Link } from 'react-router-dom';
import { withRouter, RouteComponentProps } from 'react-router-dom';


class CustomersServicesSection extends React.Component<RouteComponentProps> {

    sendContactData = () => {
        this.props.history.push({
            pathname: '/contact-us',
            search: "topic=General question or comment"
        })
    }

    render() {
        return (
            <div className="customer-service-main-section" >
                <div className="customer-content-body">
                    <div className="customer-serve-head">
                        <h2> Customers </h2>
                        <h2 className="h2-lighter"> we serve </h2>
                    </div>
                    <div className="customer-body-text">
                        <div className="serv-text">
                            <div className="hd-txt">Lenders</div>
                            <div className="hd-paragraph">The level of experience our escrow personnel has will give you confidence to facilitate even the most complex of transactions. Utilizing integrations with many of the top lenders you will find our process far exceeds expectations.</div>
                        </div>
                        <div id="middle-column" className="serv-text">
                            <div className="hd-txt">Real Estate Pros</div>
                            <div className="hd-paragraph">We continue to set the bar on making our real estate professionals look good. By utilizing our many tools, professional staff and brilliant technology we make the closing process transparent and pain free for your clients. Everyone should experience the TRULY difference.</div>
                        </div>
                        <div className="serv-text">
                            <div className="hd-txt">Consumers</div>
                            <div className="hd-paragraph">The way you judge a real estate transaction is how empowered you are in the process. Are you informed all the way through the process? At TRULY we make it our priority to give you all the information you need to feel good all the way through the process.</div>
                        </div>
                    </div>
                    <div className="for-more-information-paragraph">
                        For more information on the title services we offer, contact us today.
                    </div>
                    <div className="button-section">
                        <div onClick={this.sendContactData}>
                            <div className="get-contact-us">
                                Get In Touch
						</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default withRouter(CustomersServicesSection);