import * as React from 'react';
import Slider from 'react-slick';
import { NavHashLink as NavLink } from 'react-router-hash-link';

const NextArrow = (props: any) => {
	const { className, style, onClick } = props;
	return (
		<div className="next-arrow-container" style={{}} onClick={onClick}>
			<div className="next-arrow-img"/>
		</div>
	)
}

const PrevArrow = (props: any) => {
	const { className, style, onClick } = props;
	return (
		<div className="prev-arrow-container" style={{}} onClick={onClick}>
			<div className="prev-arrow-img"/>
		</div>
	)
}

class Carousel extends React.Component<any, any> {
	render() {
		const settings = {
			dots: true,
			infinite: true,
			speed: 750,
			nextArrow: <NextArrow />,
			prevArrow: <PrevArrow />
		}
		return (
			<div className="carousel-content-container">
				<Slider {...settings}>
					<div>
						<div className="slider-content-title">Platform</div>
						<p className="slider-content-paragraph">
							Imagine that you can have complete transparency into your real estate transaction, no matter if you are the real estate professional, lender or consumer? You Can!  This is what makes our team perform at the highest level every day.
						</p>
						<NavLink to="/technology#integrated-platform-container" scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -64) }}>
							<p className="slider-content-link">More...</p>
						</NavLink>
					</div>
					<div>
						<div className="slider-content-title">Infrastructure</div>
						<p className="slider-content-paragraph">
							Truly has a ton of experience in its ranks building highly-secure, cloud based infrastructure that most companies only dream of. We build systems that let our team focus on the client, through efficiencies that our systems provide.
						</p>
						<NavLink to="/technology#infrastructure-container" scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -64) }}>
							<p className="slider-content-link">More...</p>
						</NavLink>
					</div>
					<div>
						<div className="slider-content-title">The Right Tools</div>
						<p className="slider-content-paragraph">
							By utilizing great analytics and state of the art technology, you will quickly realize that Truly has the best tools in the industry. From our platform to our data sources we strive to provide our clients with the most useful and innovative tools around.
						</p>
						<NavLink to="/technology/#supportive-tech-container" scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -64) }}>
							<p className="slider-content-link">More...</p>
						</NavLink>
					</div>
				</Slider>				
			</div>
		);
	}
}

export default Carousel;