import * as React from 'react';
import { Link } from 'react-scroll';

// --------------
// components
import ContactUsPageContainter from '../ContactUs/ContactUsPageContainter';
import Footer from '../footer';
import JoinUs from './joinUsSection';
import NavBar from '../navBar';
import ServicesSection from './servicesSection';
import TechnologySection from './technologySection';
import WhyTruly from './whyTrulySection';
import { TrulyLicensedState } from '../../types/officeLocations';
import { withRouter } from 'react-router-dom';

interface Props {
    openSignUpForm: () => void;
    userState: TrulyLicensedState | null;
}

const HeroContentBase = () => (
    <>
        <h1 id="hero-title-lighter">There truly is a</h1>
        <h1 className="hero-title-difference" >Difference.</h1>
    </>
);


const HeroContentIL = () => (
    <h1 id="hero-title-lighter">
        Introducing <span className='hero-title-difference'>Truly Title Illinois. Services</span> you can count on.
    </h1>
);

// TX/FL/UT will get their own styles down the line so leave these empty for now
const HeroContentTX = () => {
    return (
        <div className='navbar-hero-container TX'>

        </div>
    );
}

const HeroContentFL = () => {
    return (
        <div className='navbar-hero-container FL'>

        </div>
    );
}

const HeroContentUT = () => {
    return (
        <div className='navbar-hero-container UT'>

        </div>
    );
}

class HomePage extends React.Component<Props, any> {

    constructor(props: Props) {
        super(props);

    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }






    render() {
        const { openSignUpForm, userState } = this.props;

        return (
            <div>
                <div>
                    <div className="homepage-container">
                        {/* only adding the custom userState class styles for IL right now, everything else gets the base styles */}
                        <div className={`navbar-hero-container ${userState === 'IL' ? userState : 'base'}`}>
                            <NavBar location="homepage" openSignUpForm={openSignUpForm} userState={userState} />
                            <div className="homepage-hero-container">
                                <div className="hero-text-content-container">
                                    {/* every state except IL gets the base content for this ticket https://gitlab.com/flueid/truly-title/-/issues/6 */}
                                    {userState === null && <HeroContentBase />}
                                    {userState === 'TX' && <HeroContentBase />}
                                    {userState === 'FL' && <HeroContentBase />}
                                    {userState === 'UT' && <HeroContentBase />}
                                    {userState === 'IL' && <HeroContentIL />}
                                    <p className="hero-paragraph">
                                        Combining industry leading technology with years of experience and a focus on the customer gives our clients a TRULY unique user experience.
					                    </p>
                                    <Link to="homepage-services-container" offset={-80} smooth={true} duration={750} className="hero-learn-more-button">
                                        LEARN MORE
					                    </Link>
                                </div>
                            </div>
                            <div className="hero-learn-more">
                                <p className="hero-paragraph">
                                    Combining industry leading technology with years of experience and a focus on the customer gives our clients a TRULY unique user experience.
						              </p>
                                <Link to="homepage-services-container" offset={-80} smooth={true} duration={750} className="hero-learn-more-button">
                                    LEARN MORE
				                    </Link>
                            </div>
                        </div>
                        <WhyTruly userState={userState} />
                        <TechnologySection />
                        <ServicesSection />
                        <JoinUs />
                    </div>
                    <Footer />
                </div>
            </div>

        );
    }
}

export default HomePage;
