import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';

interface State {
	elementVisible: boolean;
}

class InnovativeTechSection extends React.Component<any, State> {

	state = {
		elementVisible: false
	}

	handleVisibilityUpdate = (e: any, data: any) => {

		const { elementVisible } = this.state;
		const { topVisible, bottomVisible } = data.calculations;

		if (!elementVisible && (topVisible || bottomVisible)) {
			this.setState({ elementVisible: true })
		}
	}

	render() {

		const { elementVisible } = this.state;

		return (			
			<div className="innovative-tech-container">
				<div className="innovative-tech-content">
					<div className="tech-text-container">
						<h2>Innovative</h2>
						<h2 className="title-bolder">Technology</h2>
						<p>We designed our technology to simplify the complex. With streamlined order processing your transactions will close accurately and on time. Our online and mobile tools improve efficiency and collaboration. Get ready to experience a new level of transparency, reliability, and service. </p>
					</div>
					<Visibility fireOnMount onUpdate={this.handleVisibilityUpdate} style={{ minHeight: "190px" }}>
						<Transition visible={elementVisible} animation="fade up" duration={2500}>
							<div className="img-container" />
						</Transition>
					</Visibility>					
				</div>
			</div>			
		);
	}
}

export default InnovativeTechSection;