/// <reference types="@types/googlemaps" />

export default class GoogleMaps {

    static StateAddressType = 'administrative_area_level_1';

    static Geocoder(): google.maps.Geocoder | undefined {
        // still have to use this hacky way of getting the google API off the window object because typescript complains about "window.google"
        const googleWindow: any = window;
        const googleAPI = googleWindow.google as typeof google;
        if (googleAPI) return new googleAPI.maps.Geocoder();        
    }

    static async GeocodeLatLng(geocoder: google.maps.Geocoder, position: google.maps.LatLngLiteral): Promise<google.maps.GeocoderResult> {

        const request: google.maps.GeocoderRequest = {
            location: position
        }

        return new Promise((resolve, reject) => {
            geocoder.geocode(request, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
                if (status.toString() === 'OK') {
                    if (results.length) {
                        const topResult: google.maps.GeocoderResult = results[0];
                        resolve(topResult);
                    } else {
                        reject('No results found.');
                    }
                } else {
                    reject('Geocoder failded due to: ' + status);
                }
            });
        });
    }

    static FindStateAddressComponent(addressComponents: google.maps.GeocoderAddressComponent[]): string {
        let state: string = '';

        const result = addressComponents.find(x => {
            return x.types.includes(GoogleMaps.StateAddressType);
        });
        if (result) state = result.short_name;

        return state;
    }
}
