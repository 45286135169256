import * as React from "react";
import { Transition, Dimmer, Loader } from "semantic-ui-react";
import appConfig from "../../appConfig";
import { callEmailHandler } from "../../utilities/apiService";
import { signUpEmailBody } from "./utils";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { red, deepOrange } from "@material-ui/core/colors";
import NavBar from "../navBar";

const roles = [
  {
    value: "Finance Pro",
    label: "Finance Pro",
  },
  {
    value: "Real Estate Pro",
    label: "Real Estate Pro",
  },
  {
    value: "Assistant",
    label: "Assistant",
  },
  {
    value: "Title Officer",
    label: "Title Officer",
  },
  {
    value: "Escrow Officer",
    label: "Escrow Officer",
  },
  {
    value: "Account Executive",
    label: "Account Executive",
  },
  {
    value: "Transaction Coordinator",
    label: "Transaction Coordinator",
  },
  {
    value: "Other",
    label: "Other",
  },
];

const companyTypeOptions = [
  {
    value: "Real Estate",
    label: "Real Estate",
  },
  {
    value: "Mortgage Broker",
    label: "Mortgage Broker",
  },
  {
    value: "Lender",
    label: "Lender",
  },
  {
    value: "Other",
    label: "Other",
  },
];

const states = [
  {
    value: "Alabama",
    abbreviation: "AL",
    label: "Alabama",
  },
  {
    value: "Alaska",
    abbreviation: "AK",
    label: "Alaska",
  },
  {
    value: "Arizona",
    abbreviation: "AZ",
    label: "Arizona",
  },
  {
    value: "Arkansas",
    abbreviation: "AR",
    label: "Arkansas",
  },
  {
    value: "California",
    abbreviation: "CA",
    label: "California",
  },
  {
    value: "Colorado",
    abbreviation: "CO",
    label: "Colorado",
  },
  {
    value: "Connecticut",
    abbreviation: "CT",
    label: "Connecticut",
  },
  {
    value: "Delaware",
    abbreviation: "DE",
    label: "Delaware",
  },
  {
    value: "District Of Columbia",
    abbreviation: "DC",
    label: "District Of Columbia",
  },
  {
    value: "Florida",
    abbreviation: "FL",
    label: "Florida",
  },
  {
    value: "Georgia",
    abbreviation: "GA",
    label: "Georgia",
  },
  {
    value: "Hawaii",
    abbreviation: "HI",
    label: "Hawaii",
  },
  {
    value: "Idaho",
    abbreviation: "ID",
    label: "Idaho",
  },
  {
    value: "Illinois",
    abbreviation: "IL",
    label: "Illinois",
  },
  {
    value: "Indiana",
    abbreviation: "IN",
    label: "Indiana",
  },
  {
    value: "Iowa",
    abbreviation: "IA",
    label: "Iowa",
  },
  {
    value: "Kansas",
    abbreviation: "KS",
    label: "Kansas",
  },
  {
    value: "Kentucky",
    abbreviation: "KY",
    label: "Kentucky",
  },
  {
    value: "Louisiana",
    abbreviation: "LA",
    label: "Louisiana",
  },
  {
    value: "Maine",
    abbreviation: "ME",
    label: "Maine",
  },
  {
    value: "Maryland",
    abbreviation: "MD",
    label: "Maryland",
  },
  {
    value: "Massachusetts",
    abbreviation: "MA",
    label: "Massachusetts",
  },
  {
    value: "Michigan",
    abbreviation: "MI",
    label: "Michigan",
  },
  {
    value: "Minnesota",
    abbreviation: "MN",
    label: "Minnesota",
  },
  {
    value: "Mississippi",
    abbreviation: "MS",
    label: "Mississippi",
  },
  {
    value: "Missouri",
    abbreviation: "MO",
    label: "Missouri",
  },
  {
    value: "Montana",
    abbreviation: "MT",
    label: "Montana",
  },
  {
    value: "Nebraska",
    abbreviation: "NE",
    label: "Nebraska",
  },
  {
    value: "Nevada",
    abbreviation: "NV",
    label: "Nevada",
  },
  {
    value: "New Hampshire",
    abbreviation: "NH",
    label: "New Hampshire",
  },
  {
    value: "New Jersey",
    abbreviation: "NJ",
    label: "New Jersey",
  },
  {
    value: "New Mexico",
    abbreviation: "NM",
    label: "New Mexico",
  },
  {
    value: "New York",
    abbreviation: "NY",
    label: "New York",
  },
  {
    value: "North Carolina",
    abbreviation: "NC",
    label: "North Carolina",
  },
  {
    value: "North Dakota",
    abbreviation: "ND",
    label: "North Dakota",
  },
  {
    value: "Ohio",
    abbreviation: "OH",
    label: "Ohio",
  },
  {
    value: "Oklahoma",
    abbreviation: "OK",
    label: "Oklahoma",
  },
  {
    value: "Oregon",
    abbreviation: "OR",
    label: "Oregon",
  },
  {
    value: "Pennsylvania",
    abbreviation: "PA",
    label: "Pennsylvania",
  },
  {
    value: "Rhode Island",
    abbreviation: "RI",
    label: "Rhode Islan",
  },
  {
    value: "South Carolina",
    abbreviation: "SC",
    label: "South Carolina",
  },
  {
    value: "South Dakota",
    abbreviation: "SD",
    label: "South Dakota",
  },
  {
    value: "Tennessee",
    abbreviation: "TN",
    label: "Tennessee",
  },
  {
    value: "Texas",
    abbreviation: "TX",
    label: "Texas",
  },
  {
    value: "Utah",
    abbreviation: "UT",
    label: "Utah",
  },
  {
    value: "Vermont",
    abbreviation: "VT",
    label: "Vermont",
  },
  {
    value: "Virginia",
    abbreviation: "VA",
    label: "Virginia",
  },
  {
    value: "Washington",
    abbreviation: "WA",
    label: "Washington",
  },
  {
    value: "West Virginia",
    abbreviation: "WV",
    label: "West Virginia",
  },
  {
    value: "Wisconsin",
    abbreviation: "WI",
    label: "Wisonsin",
  },
  {
    value: "Wyoming",
    abbreviation: "WY",
    label: "Wyoming",
  },
];

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      //width: "100%",
      //[theme.breakpoints.down("md")]: {
      //	marginTop: "30px"
      //}
      //[theme.breakpoints.between("sm", "md")]: {
      //	width: "100%",
      //},
      //[theme.breakpoints.down('xs')]: {
      //	width: "100%",
      //},
    },

    inputTextFields: {
      width: "47%",
      marginRight: "0",
      marginBottom: "0",
      //[theme.breakpoints.down("md")]: {
      //	width: "100%",
      //	marginTop: "30px"
      //},

      //[theme.breakpoints.down('xs')]: {
      //	width: "100%",
      //	marginTop: "-20px",
      //	marginBottom: "50px",

      //},
    },

    cssLabel: {
      "&$cssFocused": {
        color: "#969696",
        fontSize: 14,
      },
      fontSize: "18px",
      position: "absolute",
      top: "-5px",
      fontFamily: "Proxima-nova, sans-serif",
    },
    cssFocused: {},

    cssOutlinedInput: {
      "&$cssFocused $notchedOutline": {
        borderColor: "F6490D",
      },
    },
    notchedOutline: {},
  });

const theme = createMuiTheme({
  palette: {
    primary: deepOrange,
    error: red,
  },
});

interface Props {
  classes: any;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  accountManager: string;
  companyName: string;
  companyType: string;
  companyAddress: string;
  city: string;
  state: string;
  zipCode: string;
}

class SignUpForm extends React.Component<Props, any> {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    role: "",
    accountManager: "",
    companyName: "",
    companyType: "",
    companyAddress: "",
    city: "",
    state: "",
    zipCode: "",

    firstNameComplete: true,
    lastNameComplete: true,
    emailComplete: true,
    phoneComplete: true,
    roleComplete: true,
    companyNameComplete: true,
    companyTypeComplete: true,
    companyAddressComplete: true,
    cityComplete: true,
    stateComplete: true,
    zipCodeComplete: true,

    emailValid: true,
    phoneValid: true,
    zipCodeValid: true,

    formHidden: false,
    showSuccessMessage: false,
    showFailureMessage: false,
    submitButtonEnabled: true,
  };

  handleInput = (e: any) => {
    e.persist();

    // still trying to figure out a way to make a "key signature" so typescript doesn't complain about using
    // [e.targer.name] for dynamically setting state properties
    this.setState({ [e.target.name]: e.target.value }, () => {
      const {
        firstName,
        lastName,
        companyName,
        role,
        city,
        state,
        email,
        phone,
        companyAddress,
        companyType,
        zipCode,

        firstNameComplete,
        lastNameComplete,
        companyNameComplete,
        roleComplete,
        cityComplete,
        stateComplete,
        emailValid,
        phoneValid,
        zipCodeValid,
        companyAddressComplete,
        companyTypeComplete,
        zipCodeComplete,
      } = this.state;

      // if user enters something into the field, "...Complete" property becomes true
      switch (e.target.name) {
        case "firstName":
          // these conditions prevent extra re-render
          if (!firstNameComplete) {
            this.setState({ firstNameComplete: true });
          }
          break;
        case "lastName":
          if (!lastNameComplete) {
            this.setState({ lastNameComplete: true });
          }
          break;
        case "email":
          if (!emailValid) {
            this.setState({ emailValid: true });
          }
          break;
        case "phone":
          if (!phoneValid) {
            this.setState({ phoneValid: true });
          }
          break;
        case "role":
          if (!roleComplete) {
            this.setState({ roleComplete: true });
          }
          break;
        case "companyName":
          if (!companyNameComplete) {
            this.setState({ companyNameComplete: true });
          }
          break;
        case "companyType":
          if (!companyTypeComplete) {
            this.setState({ companyTypeComplete: true });
          }
          break;
        case "companyAddress":
          if (!companyAddressComplete) {
            this.setState({ companyAddressComplete: true });
          }
          break;
        case "city":
          if (!cityComplete) {
            this.setState({ cityComplete: true });
          }
          break;
        case "state":
          if (!stateComplete) {
            this.setState({ stateComplete: true });
          }
          break;
        case "zipCode":
          if (!zipCodeValid) {
            this.setState({ zipCodeValid: true });
          }
          break;
      }
    });
  };

  clearForm = () => {
    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      role: "",
      accountManager: "",
      companyName: "",
      companyType: "",
      companyAddress: "",
      city: "",
      state: "",
      zipCode: "",

      firstNameComplete: true,
      lastNameComplete: true,
      emailComplete: true,
      phoneComplete: true,
      roleComplete: true,
      companyNameComplete: true,
      companyTypeComplete: true,
      companyAddressComplete: true,
      cityComplete: true,
      stateComplete: true,
      zipCodeComplete: true,

      emailValid: true,
      phoneValid: true,
      zipCodeValid: true,

      formHidden: false,
      showSuccessMessage: false,
      showFailureMessage: false,
      submitButtonEnabled: true,
    });
  };

  closeForm = () => {
    this.clearForm();
  };

  submitForm = () => {
    const {
      firstName,
      lastName,
      email,
      phone,
      role,
      accountManager,
      companyName,
      companyType,
      companyAddress,
      city,
      state,
      zipCode,

      firstNameComplete,
      lastNameComplete,
      companyNameComplete,
      companyTypeComplete,
      companyAddressComplete,
      roleComplete,
      cityComplete,
      stateComplete,

      emailValid,
      phoneValid,
      zipCodeValid,

      submitButtonEnabled,
    } = this.state;

    if (!firstName) {
      this.setState({ firstNameComplete: false });
    }

    if (!lastName) {
      this.setState({ lastNameComplete: false });
    }

    if (!companyName) {
      this.setState({ companyNameComplete: false });
    }

    if (!companyType) {
      this.setState({ companyTypeComplete: false });
    }

    if (!companyAddress) {
      this.setState({ companyAddressComplete: false });
    }

    if (!role) {
      this.setState({ roleComplete: false });
    }

    if (!city) {
      this.setState({ cityComplete: false });
    }

    if (!state) {
      this.setState({ stateComplete: false });
    }

    if (!zipCode || !this.validateZipCode(zipCode)) {
      this.setState({ zipCodeValid: false });
    }

    if (!email || !this.validateEmail(email)) {
      this.setState({ emailValid: false });
    }

    if (!phone || !this.validatePhone(phone)) {
      this.setState({ phoneValid: false });
    }

    if (
      firstName &&
      lastName &&
      companyName &&
      companyAddress &&
      companyType &&
      role &&
      city &&
      state &&
      zipCodeValid &&
      emailValid &&
      phoneValid
    ) {
      if (submitButtonEnabled) {
        this.setState({ submitButtonEnabled: false }, () => {
          const signUpData: SignUpRequest = {
            firstName: firstName,
            lastName: lastName,
            companyName: companyName,
            role: role,
            city: city,
            state: state,
            email: email,
            phone: phone,
            accountManager: accountManager,
            companyType: companyType,
            companyAddress: companyAddress,
            zipCode: zipCode,
          };

          const recipients = ["titlefy@trulytitle.com"];

          callEmailHandler({
            to: recipients,
            subject: "Sign-Up submission from TrulyTitle.com",
            text: signUpEmailBody(signUpData),
          })
            .then((response) => {
              if (response.ok) {
                setTimeout(() => {
                  this.setState({ showSuccessMessage: true });
                }, 1500);
              } else {
                setTimeout(() => {
                  this.setState({ showFailureMessage: true });
                }, 1500);
              }
            })
            .catch((data) => {
              setTimeout(() => {
                this.setState({ showFailureMessage: true });
              }, 1500);
            });
        });
      }
    }
  };

  validateZipCode = (zipCode: string) => {
    return /^[0-9]{5}(?:-[0-9]{4})?$/.test(zipCode) ? true : false;
  };

  validateEmail = (email: string) => {
    return /^\S+@\S+$/.test(email) ? true : false;
  };

  validatePhone = (phone: string) => {
    return phone.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
      ? true
      : false;
  };

  renderSuccessMessage = () => {
    return (
      <div className="content-container">
        <div className="header">Success!</div>
        <p className="description-paragraph">
          We will contact you shortly to confirm we have everything correct so
          that we can enable your new account.
        </p>
        <div className="troole-graphic" />
        <div onClick={this.closeForm} className="sign-up-btn confirm-close-btn">
          Got it!
        </div>
      </div>
    );
  };

  renderFailureMessage = () => {
    return (
      <div className="content-container">
        <div className="header">Oops!</div>
        <p className="description-paragraph">
          Something went wrong. We are looking into the issue. Please try again
          later.
        </p>
        <div className="troole-graphic" />
        <div onClick={this.closeForm} className="sign-up-btn confirm-close-btn">
          Got it
        </div>
      </div>
    );
  };

  renderForm = () => {
    const {
      firstName,
      lastName,
      companyName,
      companyType,
      companyAddress,
      role,
      city,
      state,
      email,
      phone,
      zipCode,
      accountManager,

      firstNameComplete,
      lastNameComplete,
      companyNameComplete,
      companyTypeComplete,
      companyAddressComplete,
      roleComplete,
      cityComplete,
      stateComplete,
      emailValid,
      phoneValid,
      zipCodeValid,

      showSuccessMessage,
      formHidden,
      submitButtonEnabled,
    } = this.state;

    const { classes } = this.props;

    return (
      <div className="content-container">
        <div className="header">Sign Up</div>
        <p className="description-paragraph">
          Start working with Truly Title today! Fill out the form below and a
          Truly Title representative will get in touch with you.
        </p>
        <div className="form-container">
          <div className="inputs-fields">
            <div className="fields-main">
              <MuiThemeProvider theme={theme}>
                <TextField
                  className="custom-input"
                  label="First Name"
                  name="firstName"
                  required
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  onChange={this.handleInput}
                  value={firstName}
                  error={!firstNameComplete}
                />
              </MuiThemeProvider>
              <MuiThemeProvider theme={theme}>
                <TextField
                  className="custom-input"
                  label="Last Name"
                  name="lastName"
                  required
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  onChange={this.handleInput}
                  value={lastName}
                  error={!lastNameComplete}
                />
              </MuiThemeProvider>
            </div>

            <div className="fields-main">
              <MuiThemeProvider theme={theme}>
                <TextField
                  className="custom-input"
                  label="Email"
                  name="email"
                  required
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  onChange={this.handleInput}
                  onBlur={() => {
                    if (email) {
                      this.validateEmail(email)
                        ? this.setState({ emailValid: true })
                        : this.setState({ emailValid: false });
                    }
                  }}
                  value={email}
                  error={!emailValid}
                />
              </MuiThemeProvider>
              <MuiThemeProvider theme={theme}>
                <TextField
                  className="custom-input"
                  label="Phone #"
                  name="phone"
                  required
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  onChange={this.handleInput}
                  onBlur={() => {
                    if (phone) {
                      this.validatePhone(phone)
                        ? this.setState({ phoneValid: true })
                        : this.setState({ phoneValid: false });
                    }
                  }}
                  value={phone}
                  error={!phoneValid}
                />
              </MuiThemeProvider>
            </div>

            <div className="fields-main">
              <MuiThemeProvider theme={theme}>
                <TextField
                  className="custom-input"
                  select
                  label="Your Role"
                  name="role"
                  required
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  onChange={this.handleInput}
                  value={role}
                  error={!roleComplete}
                >
                  {roles.map((role) => (
                    <MenuItem key={role.value} value={role.value}>
                      {role.label}
                    </MenuItem>
                  ))}
                </TextField>
              </MuiThemeProvider>
              <MuiThemeProvider theme={theme}>
                <TextField
                  className="custom-input"
                  label="Truly Account Manager (if you have one)"
                  name="accountManager"
                  id="account-manager-field"
                  InputLabelProps={{
                    classes: {
                      root: `${classes.cssLabel} account-manager-field-label`,
                      focused: classes.cssFocused,
                    },
                  }}
                  onChange={this.handleInput}
                  value={accountManager}
                />
              </MuiThemeProvider>
            </div>

            <div className="fields-main">
              <MuiThemeProvider theme={theme}>
                <TextField
                  className="custom-input"
                  label="Company Name"
                  name="companyName"
                  required
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  onChange={this.handleInput}
                  value={companyName}
                  error={!companyNameComplete}
                />
              </MuiThemeProvider>
              <MuiThemeProvider theme={theme}>
                <TextField
                  className="custom-input"
                  select
                  label="Company Type"
                  name="companyType"
                  required
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  onChange={this.handleInput}
                  value={companyType}
                  error={!companyTypeComplete}
                >
                  {companyTypeOptions.map((companyType) => (
                    <MenuItem key={companyType.value} value={companyType.value}>
                      {companyType.label}
                    </MenuItem>
                  ))}
                </TextField>
              </MuiThemeProvider>
            </div>

            <div className="fields-main">
              <MuiThemeProvider theme={theme}>
                <TextField
                  className="custom-input full-width"
                  label="Company Address"
                  name="companyAddress"
                  required
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  onChange={this.handleInput}
                  value={companyAddress}
                  error={!companyAddressComplete}
                />
              </MuiThemeProvider>
            </div>

            <div className="fields-main">
              <MuiThemeProvider theme={theme}>
                <TextField
                  className="custom-input"
                  label="City"
                  name="city"
                  required
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  onChange={this.handleInput}
                  value={city}
                  error={!cityComplete}
                />
              </MuiThemeProvider>
              <MuiThemeProvider theme={theme}>
                <TextField
                  className="custom-input quarter-width"
                  select
                  label="State"
                  name="state"
                  required
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  onChange={this.handleInput}
                  value={state}
                  error={!stateComplete}
                >
                  {states.map((state) => (
                    <MenuItem key={state.value} value={state.value}>
                      {state.label}
                    </MenuItem>
                  ))}
                </TextField>
              </MuiThemeProvider>
              <MuiThemeProvider theme={theme}>
                <TextField
                  className="custom-input quarter-width"
                  label="Zip Code"
                  name="zipCode"
                  required
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  onChange={this.handleInput}
                  value={zipCode}
                  error={!zipCodeValid}
                />
              </MuiThemeProvider>
            </div>
          </div>
        </div>
        <div onClick={this.submitForm} className="sign-up-btn">
          {submitButtonEnabled ? (
            "Sign Up For Flueid Pro"
          ) : (
            <Dimmer inverted active>
              <Loader>Processing...</Loader>
            </Dimmer>
          )}
        </div>
        <div className="small-paragraph">
          Your data is secure and will never be shared with outside parties.
        </div>
        {!submitButtonEnabled ? (
          <Dimmer inverted active>
            <Loader>Processing...</Loader>
          </Dimmer>
        ) : (
          ""
        )}
      </div>
    );
  };

  render() {
    const {
      firstName,
      lastName,
      companyName,
      role,
      city,
      state,
      email,
      phone,
      accountManager,
      firstNameComplete,
      lastNameComplete,
      companyNameComplete,
      roleComplete,
      cityComplete,
      stateComplete,
      emailValid,
      phoneValid,
      showSuccessMessage,
      showFailureMessage,
      formHidden,
    } = this.state;

    const { classes } = this.props;

    const formVisible = showSuccessMessage || showFailureMessage ? false : true;
    const successMessageVisible =
      formHidden && showSuccessMessage ? true : false;
    const failureMessageVisible =
      formHidden && showFailureMessage ? true : false;

    return (
      <div className="signup-bg">
        <div className="navbar-content signup-page">
          <NavBar location="signup-page" openSignUpForm={() => {}} />
          <div id="signup-page">
            <Transition
              onHide={() => this.setState({ formHidden: true })}
              unmountOnHide={true}
              visible={formVisible}
              animation="fade"
              duration={500}
            >
              {this.renderForm()}
            </Transition>

            <Transition
              unmountOnHide={true}
              visible={successMessageVisible}
              animation="fade"
              duration={500}
            >
              {this.renderSuccessMessage()}
            </Transition>

            <Transition
              unmountOnHide={true}
              visible={failureMessageVisible}
              animation="fade"
              duration={500}
            >
              {this.renderFailureMessage()}
            </Transition>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SignUpForm);
