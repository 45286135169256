import * as React from 'react';
import Carousel from './technologyCarousel';
import { NavLink } from 'react-router-dom';
import { Visibility, Transition } from 'semantic-ui-react';

interface State {
	elementVisible: boolean;
}

class TechnologySection extends React.Component<any, State> {

	state = {
		elementVisible: false
	}

	handleVisibilityUpdate = (e: any, data: any) => {
		const { elementVisible } = this.state;
		const { topVisible, bottomVisible } = data.calculations;

		if (!elementVisible && (topVisible || bottomVisible)) {
			this.setState({
				elementVisible: true,
			})
		}
	}

	render() {

		const { elementVisible } = this.state;

		return (	
            <Visibility fireOnMount onUpdate={this.handleVisibilityUpdate} className="homepage-visibility-technology-container">
				<div className="homepage-technology-container">
					<div className="tech-content-container">
						<div className="tech-text-background-container">
							<div className="tech-text-content">
								<h2>Innovative</h2>
								<h2 className="tech-bigger-title">Technology</h2>								
								<div className="tech-text-paragraphs">
									<p>Our team is constantly challenged to build technology that:</p> 
									<ol>
										<li>Empower the Consumer</li>
										<li>Makes our teams life easier</li>
										<li>Is really cool. They constantly knock it out of the park!</li>
									</ol>
									<NavLink to="/technology">
										<p className="paragraph-end-link">Learn more...</p>
									</NavLink>
								</div>								
							</div>								
						</div>
						<Transition visible={elementVisible} animation="fade up" duration={2500}>						
							<div className="tech-transition-container">
								<div className="tech-red-block" />
								<div className="tech-container-column-right">
									<div className="tech-img-container" />
								</div>
								<div className="tech-carousel-container">
									<Carousel />
								</div>	
							</div>
						</Transition>		
						<div className="tech-carousel-container-small-screen">
							<Carousel />
						</div>
					</div>
				</div>
			</Visibility>
		);
	}
}

export default TechnologySection;