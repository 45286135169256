/// <reference types="@types/googlemaps" />

export async function getUserPosition(): Promise<google.maps.LatLngLiteral> {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {

            // on success, send up user's position
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({ lat: position.coords.latitude, lng: position.coords.longitude });
                },
                (error) => {
                    reject(error);                    
                    console.log(error.message);
                },
                // keep commented out for now. Some users experiencing timeout issues. https://gitlab.com/flueid/truly-title/-/issues/13
                // timeout option will automatically invoke error callback if 4s passes before response
                // really helpful for users with mac system location preferences disabled for browsers
                //{ timeout: 1500 }
            );

        } else {
            reject('Location services do not exist on this browser');
        };
    });        
}