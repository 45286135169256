import * as React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { NavHashLink as NavLink } from 'react-router-hash-link';

const NextArrow = (props: any) => {
	const { className, style, onClick } = props;
	return (
		<div className="next-arrow" style={{}}>

		</div>
	);
}

const PrevArrow = (props: any) => {
	const { className, style, onClick } = props;
	return (
		<div className="prev-arrow" style={{}}>

		</div>
	);
}

interface State {
	cardsPerSlide: number;
}

class WhyTrulyCarousel extends React.Component<any, State> {

	state = {
		cardsPerSlide: 3
	}

	componentDidMount() {

		// the screen width will determine how many cards per slide
		if (window.innerWidth >= 1150) {
			this.setState({ cardsPerSlide: 3 })
		} else if (window.innerWidth < 1150 && window.innerWidth >= 768) {
			this.setState({ cardsPerSlide: 2 })
		} else if (window.innerWidth < 768) {
			this.setState({ cardsPerSlide: 1 })
		}

		window.addEventListener("resize", this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleResize);
	}

	handleResize = () => {

		const { cardsPerSlide } = this.state;

		if (window.innerWidth >= 1150 && cardsPerSlide !== 3) {
			this.setState({ cardsPerSlide: 3 })
		} else if (window.innerWidth < 1150 && window.innerWidth >= 768 && cardsPerSlide !== 2) {
			this.setState({ cardsPerSlide: 2 })
		} else if (window.innerWidth < 768 && cardsPerSlide !== 1) {
			this.setState({ cardsPerSlide: 1})
		}
	}

	renderCard = (iconName: string, title: string, paragraph: string, linkPathname: string, index: number) => {
		return (
			<div className="truly-description-card" key={index}>
				<div className="description-card-content-container">
					<div id={`why-truly-${iconName}-icon`} className="why-truly-icon"></div>
					<h1 className="description-card-title">{`${title}`}</h1>
					<p className="description-card-paragraph">{`${paragraph}`}</p>
				</div>
				{linkPathname === "/about#core-values" ?
					<NavLink to={linkPathname} scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -64) }}>
						<div className="why-truly-read-more-button">
							Read More
						</div>
					</NavLink> 
					:
					<Link to={linkPathname}>
						<div className="why-truly-read-more-button">
							Read More
						</div>
					</Link>
				}				
			</div>
		);
	}

	buildCardGroupings = (cardsPerSlide: number, cards: any[]) => {

		// This method builds a custom data structure groupings.
			// groupings is an object with keys pointing to slices of the cards list.
			// the size of these slices is determined by how many cards per slide there are

		// Example of what groupings will look like with the 2 cards per slide
			//const groupings = {
			//	"group1": cards.slice(0, 2),
			//	"group2": cards.slice(2)
			//};

		interface Groupings {
			[key: string]: any[];
		}

		let groupings: Groupings = {};
		let j = 1;

		if (cards.length <= cardsPerSlide) {
			groupings["group1"] = cards;
		} else {
			for (let i = 0; i < cards.length; i += cardsPerSlide) {
				groupings[`group${j}`] = cards.slice(i, i + cardsPerSlide);
				j += 1;
			}
		}

		return groupings;
	}

	renderCardSlides = (cards: any[]) => {

		// this method builds an array of jsx elements or "slides" that get fed to the <Slider/>

		const { cardsPerSlide } = this.state;

		const groupings = this.buildCardGroupings(cardsPerSlide, cards);

		const slides = [];

		for (const group in groupings) {
			slides.push(
				<div className="description-card-slide-container" key={group}>
					{groupings[group].map((card, index) => this.renderCard(card.iconName, card.title, card.paragraph, card.linkPathname, index))}
				</div>
			)
		}

		return slides;

	}

	render() {

		const settings = {
			dots: true,
			infinite: true,
			speed: 750,
			nextArrow: <NextArrow />,
			prevArrow: <PrevArrow />
		}

		const whyTrulyCards = [
			{
				iconName: "tech",
				title: "Technology",
				paragraph: "Our team has spent decades transforming the industry through the development of disruptive digital platforms. A platform that places revolutionary products at our client’s fingertips.",
				linkPathname: "/technology"
			},
			{
				iconName:"settlement",
				title: "Settlement Services",
				paragraph: "The closing experience represents how consumers judge their home buying process. We have built our systems around communication and transparency delivering a TRULY positive experience.",
				linkPathname: "/services"
			},
			{
				iconName: "friendly",
				title: "Friendly Services",
				paragraph: "The closing process can be confusing and cumbersome. The TRULY team was hand-picked based on their ability to leave clients and consumers with the best experience possible.",
				linkPathname: "/about#core-values"
			}
		]

		return (
			<div className="why-truly-description-cards">
					<Slider {...settings}>
						{this.renderCardSlides(whyTrulyCards)}
					</Slider>			
			</div>			
		);
	}
}

export default WhyTrulyCarousel;