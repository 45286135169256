import * as React from 'react';
import DropDownsContainer from './dropDownsContainer';
import ResourcesDocs from './resourcesDocs';
import NavBar from '../navBar';
import Footer from '../footer';
import { DivisionType, ResourceType } from '../../types/resources';

interface Props {
    openSignUpForm: () => void;
}

interface State {

    division: DivisionType,
    resource: ResourceType
}

class ResourcesPage extends React.Component<Props, State> {

    state: State = {

        division: 'All States',
        resource: 'All Resources'
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }



    handleDivisionClick = (division: DivisionType) => {
        this.setState({ division: division });
    }

    handleResourceOptionClick = (resource: ResourceType) => {
        this.setState({ resource: resource });
    }

    render() {

        const { openSignUpForm } = this.props;
        const { division, resource } = this.state;

        return (
            <div>
                {
                    <div>
                        <div className="resources-page-container">
                            <div className="navbar-hero-container">
                                <NavBar location="resources-page" openSignUpForm={openSignUpForm} />
                                <div className="resources-page-hero-container">
                                    <div className="hero-text-container">
                                        <h1>Client Resources</h1>
                                    </div>
                                </div>
                            </div>

                            <div className='resources-body'>
                                <DropDownsContainer division={division} resource={resource} handleDivisionClick={this.handleDivisionClick} handleResourceOptionClick={this.handleResourceOptionClick} />
                                <ResourcesDocs division={division} resource={resource} />
                            </div>

                        </div>
                        <Footer />
                    </div>
                }
            </div>
        );
    }
}

export default ResourcesPage;