
export default class appConfig {

	static environment(): string {
		const hostname = window && window.location && window.location.hostname;

		if (hostname.indexOf("truly-title-web-stage") > -1) {
			return "staging";
		}
		if (hostname.indexOf("trulytitle.com") > -1 || hostname.indexOf("truly-title-web-prod") > -1) {
			return "production";
		} else {
			return "development";
		}
	}

	//===========================
	//API URL
	//===========================
	static apiURL(): string {

		let environment = this.environment();

		switch (environment) {
		    case "staging":
				return "http://truly-title-api-stage.us-west-2.elasticbeanstalk.com";
			    
		    case "production":
		        return "https://api.trulytitle.com";
		        
		    default:
				return "http://localhost:64443";
		}
	}
}