import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import WhyTrulyCarousel from './whyTrulyCarousel';
import { TrulyLicensedState } from '../../types/officeLocations';

interface Props {
	userState: TrulyLicensedState | null;
}

interface State {
	elementVisible: boolean;
}

class WhyTruly extends React.Component<Props, State> {

	state = {
		elementVisible: false
	}

	handleVisibilityUpdate = (e: any, data: any) => {
		const { elementVisible } = this.state;
		const { topVisible, bottomVisible } = data.calculations;

		if (!elementVisible && (topVisible || bottomVisible)) {
			this.setState({
				elementVisible: true,
			})
		}
	}

	render() {

		const { userState } = this.props;
		const { elementVisible } = this.state;

		return (
			<div className="homepage-why-truly-container">
				<div className="why-truly-content-container">
					<Visibility fireOnMount onUpdate={this.handleVisibilityUpdate} className="visibility-why-truly-text-container">
						<div className={`why-truly-text-container ${userState || ''}`}>
							<h2>Why work with</h2>
							<h2 className="why-truly-bigger-title">Truly?</h2>
							<div className="why-truly-paragraphs">
								<p>Imagine having transparency into your transaction at every step. Imagine having people who truly care about you working on your transaction. Imagine your transaction being quick, simple and easy! Now stop imagining and start working with TRULY Title!</p>								
							</div>
						</div>
					</Visibility>
						<Transition visible={elementVisible} animation="fade up" duration={2500}>
							<div className="description-cards-container">
								<WhyTrulyCarousel />
							</div>
						</Transition>
					</div>
				</div>
		);
	}
}

export default WhyTruly;