import * as React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Label } from 'semantic-ui-react';

class Footer extends React.Component<any, any> {

	render() {

		const { pathname } = this.props.location;
		const { showContactForm } = this.props;

		return (
			<div className={pathname === "/about" ? `footer-container ${pathname.slice(1)}-page` : "footer-container"}>
				<div className="footer-content-container" id={`${pathname.slice(1)}-footer-content-container`}>
					<div className="truly-different-title">
						There <span style={{ color: "#F6490D" }}>truly</span> is a difference.
					</div>
					<div className="call-nav-items-row">
						<div className="call-today-text-container">
							<div className="call-today-text" id="call-today-smaller">
								Call us today:
							</div>
							<div className="call-today-text" id="call-today-phone-number" x-ms-format-detection="none" style={{ fontWeight: "bold", marginBottom: "10px" }}>
								(888) 4U-Truly or (888) 488-7859
							</div>
						</div>
						<div className="footer-nav-items-container">
							<Link to="/">
								<div className="footer-nav-item">Home</div>
							</Link>
							<Link to="/services">
								<div className="footer-nav-item">Services</div>
							</Link>
							<Link to="/technology">
								<div className="footer-nav-item">Technology</div>
							</Link>
							<a
								href="https://www.titlecapture.com/app-new/account/form/quick-quote?c=trulytitle"
								target="_blank"
								className="footer-nav-item"
							>
								Get A Quote
							</a>
							<Link to="/about">
								<div className="footer-nav-item">About</div>
							</Link>
							<Link to="/locations">
								<div className="footer-nav-item">Locations</div>
							</Link>
							<Link to="/contact-us">
								<div className="footer-nav-item contact" style={{ color: "#F6490D" }}>
									Contact Us
								</div>
							</Link>

						</div>
					</div>
					<div className="social-media-links-container">
						<a href="https://www.instagram.com/trulytitle/" target="_blank" className="sml-link-instagram"></a>
						<a href="https://www.facebook.com/trulytitle" target="_blank" className="sml-link-facebook"></a>
						<a href="https://www.linkedin.com/company/truly-title-inc/" target="_blank" className="sml-link-linkedin"></a>
					</div>
					<br />
					<div className="privacy-copyright-container">
						<div className="privacy-terms-container">
							<Link to="/privacy">
								<div className="privacy-policy-text">
									Privacy Policy
								</div>
							</Link>
							<div className="terms-conditions-text" style={{ textDecoration: "underline" }}>
								Terms & Conditions
							</div>
						</div>
						<div className="copyright-terms-container">
							<div className="copyright-text">
								Copyright © {new Date().getFullYear()} Truly Title, Inc.  All Rights Reserved.
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(Footer);