import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';

interface State {
	elementVisible: boolean;
}

class OurOffersSection extends React.Component<any, State> {

    state = {
        elementVisible: false
    }

    handleVisibilityUpdate = (e: any, data: any) => {

		const { elementVisible } = this.state;
		const { topVisible, bottomVisible } = data.calculations;

		if (!elementVisible && (topVisible || bottomVisible)) {
			this.setState({ elementVisible: true })
		}
    }

    render() {

		const { elementVisible } = this.state;

        return (
            <div className="our-offers-container">
                <div className="our-offers-content">
                    <div className="offers-header-container" >
                        <div className="offers-main-header-container" >
                            <h2 className="h2-lighter">What We Offer</h2>
                        </div>
                        <div className="offers-red-block-underline" />
                    </div>
                    <div className="mission-paragraph-container">
                        <Visibility fireOnMount onUpdate={this.handleVisibilityUpdate} className="visibility-paragraph-container">
                            <Transition visible={elementVisible} animation="fade up" duration={2500}>
                                <p className="offers-large-paragraph">The title and escrow professionals at Truly Title have years of experience in local real estate closing procedures, the inner workings of title insurance and the use of technology to streamline the process.  You can rest assured that you are in the best hands when working with Truly Title.</p>
                            </Transition>
                        </Visibility>
                        <div className="mission-paragraph-content"/>                       
                    </div>
                </div>
            </div>
        );
    }
}

export default OurOffersSection;