import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withRouter, RouteComponentProps } from 'react-router-dom';


interface State {
    emailInput: string;
    elementVisible: boolean;
}

class JoinUs extends React.Component<RouteComponentProps, State> {

    state = {
        emailInput: "",
        elementVisible: false
    };

    handleInputChange = (e: any) => {
        this.setState({ emailInput: e.target.value });
    };

    onSubmit = () => {
        this.setState({ emailInput: "" });
    };

    handleVisibilityUpdate = (e: any, data: any) => {
        const { elementVisible } = this.state;
        const { topVisible, bottomVisible } = data.calculations;

        if (!elementVisible && (topVisible || bottomVisible)) {
            this.setState({
                elementVisible: true,
            });
        }
    };
    sendContactData = () => {
        const { emailInput } = this.state;
        this.props.history.push({
            pathname: '/contact-us',
            search: "?email=" + emailInput + "&topic=I’m interested in joining the Truly Team"
        })
    }

    render() {
        const { emailInput, elementVisible } = this.state;

        return (
            <div className="homepage-join-container">
                <Visibility fireOnMount onUpdate={this.handleVisibilityUpdate} style={{ width: "100%", height: "100%" }}>
                    <div className="join-container">
                        <Transition visible={elementVisible} animation="fade up" duration={2500}>
                            <div className="join-us-content-container">
                                <div className="join-us-text-container">
                                    <span className="join-title small">Join our </span><span className="join-title big">Team</span>
                                    <div className="join-text-paragraph">Troole is always looking for great people to join the Truly team.  Enter your email below to get started.</div>
                                </div>
                                <div className="troole-icon-container">
                                    <div className="troole-icon" />
                                    <div className="text-link-container">
                                        <div className="meet-troole-text-link">Hi! I’m Troole, the Director of Amazement here at Truly.</div>
                                        <div className="up-arrow-icon" />
                                    </div>
                                </div>
                                <div className="troole-icon-text-small-screen">
                                    <div className="join-title-container">
                                        <span className="join-title small">Join our </span><span className="join-title big">Team</span>
                                    </div>
                                    <div className="icon-text-container">
                                        <div className="troole-icon" />
                                        <div className="small-screen-text-container">
                                            <div className="join-text-paragraph">Troole is always looking for great people to join the Truly team.  Enter your email below to get started.</div>
                                            <div className="meet-troole-text-link-container">
                                                <div className="join-text-paragraph" style={{ marginTop: "0px" }}>Hi! I’m Troole, the Director of Amazement here at Truly.</div>
                                                <div className="up-arrow-icon" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                        <div className="join-input-container">
                            <div className="join-input-content">
                                <div className="join-input-left-items">
                                    <span className="email-icon" />
                                    <input
                                        className="email-input"
                                        value={emailInput}
                                        placeholder="Enter your email"
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className="join-continue-btn">
                                    <div className="join-continue-btn" onClick={this.sendContactData}>
                                        CONTINUE
								</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Visibility>
            </div>
        );
    }
}

export default withRouter(JoinUs);