
export type DivisionType = 'All States' | 'Utah' | 'Texas' | 'Florida' | 'Arizona' | 'Illinois';
export type ResourceType = 'All Resources' | 'Helpful Guides' | 'Rosters' | 'Applications' | 'City Profiles';

export const divisionOptions: DivisionType[] = ['All States', 'Utah', 'Texas', 'Florida', 'Arizona', 'Illinois', ];
export const resourceOptions: ResourceType[] = [
    'All Resources',
    'Helpful Guides',   
    'Rosters',
    'Applications',
    'City Profiles'
];

export interface ResourceGroup {
    resourceType: ResourceType;
    resources: Resource[];
    divisionType: DivisionType;
}


export function uniqueID() {
    return Math.random().toString(36).substr(2, 9);
}

export interface Resource {
    [key: string]: any;
    resourceType: ResourceType;
    divisionType: DivisionType;
    title: string;
    link: string;
    key: string;
}

export const Resources: Resource[] = [
    {
        divisionType: "Utah",
        resourceType: "Rosters",
        key: uniqueID(),
        title: "Vernal Team",
        link: "https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/rosters/Utah-Vernal-101422.pdf"
    },
    {
        divisionType: "Utah",
        resourceType: "Rosters",
        key: uniqueID(),
        title: "Union Heights Team",
        link: "https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/rosters/Utah-UnionHeights-101422.pdf"
    },
    {
        divisionType: "Utah",
        resourceType: "Rosters",
        key: uniqueID(),
        title: "South Ogden Team",
        link: "https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/rosters/Utah-SouthOgden-101422.pdf"
    },
    {
        divisionType: "Utah",
        resourceType: "Rosters",
        key: uniqueID(),
        title: "Sugarhouse Team",
        link: "https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/rosters/Utah-Sugarhouse-110322.pdf"
    },
    {
        divisionType: 'Utah',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'Title Commitment UT',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/Title_Commitment_UT.pdf'
    },
    {
        divisionType: 'Utah',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: '5 Mortgage Closing Terms',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/5+Mortgage+Closing+Terms.pdf'
    },
    {
        divisionType: 'Utah',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: '5 Things Not to Do',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/5+Things+Not+to+Do.pdf'
    },
    {
        divisionType: 'Utah',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: '7 Reasons for Title Insurance',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/7+Reasons+for+Title+Insurance.pdf'
    },
    {
        divisionType: 'Utah',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: '10 Steps to Buying',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/10+Steps+to+Buying.pdf'
    },
    {
        divisionType: 'Utah',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'Checklist for Closing Day',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/Checklist+for+Closing+Day.pdf'
    },
    {
        divisionType: 'Utah',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'Escrow Accounts and the Closing Process',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/Escrow+Accounts+and+the+Closing+Process.pdf'
    },
    {
        divisionType: 'Utah',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'First Time Homebuyer',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/First+Time+Homebuyer.pdf'
    },
    {
        divisionType: 'Utah',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'ID Requirements at Closing',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/ID+Requirements+at+Closing.pdf'
    },
    {
        divisionType: 'Utah',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'Key Professionals in a Real Estate Transaction',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/Key+Professionals+in+a+Real+Estate+Transaction.pdf'
    },
    {
        divisionType: 'Utah',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'Rent or Buy',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/Rent+or+Buy.pdf'
    },
    {
        divisionType: 'Utah',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'Terms to Know',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/Terms+to+Know.pdf'
    },
    {
        divisionType: 'Utah',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'The Life of a Real Estate Transaction',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/The+Life+of+a+Real+Estate+Transaction.pdf'
    },
    {
        divisionType: 'Utah',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'Tips for a Timely Closing',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/Tips+for+a+Timely+Closing.pdf'
    },
    {
        divisionType: 'Utah',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'What Happens After the Closing',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/What+Happens+After+the+Closing.pdf'
    },
    {
        divisionType: 'Utah',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'Wire Fraud',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/Wire+Fraud.pdf'
    },
    {
        divisionType: 'Utah',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'Wire Fraud (Spanish)',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/Wire+Fraud_Spanish.pdf'
    },
    {
        divisionType: 'All States',
        resourceType: 'Applications',
        key: uniqueID(),
        title: 'Zoccam Deposit',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/applications/TrulyTitle_ZOCCAM_Register-Deposit.pdf'
    },
    {
        divisionType: 'All States',
        resourceType: 'Applications',
        key: uniqueID(),
        title: 'Zoccam Benefits',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/applications/TrulyTitle_ZOCCAM_benefits.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'DFW Title Commitment',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/DFW_Title-Commitment.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'Key Terms (Spanish)',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/Key+Terms+Spanish.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'Moving Checklist',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/Moving+Checklist.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'Moving Labels',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/Moving+Labels.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'Sellers Checklist',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/Sellers+Checklist.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'TX T-47 Affidavit',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/TX_T-47+Affidavit.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'TX Rates Endorsements',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/TX_rates-endorsements.pdf'
    },

    {
        divisionType: 'Texas',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'What is Escrow (Spanish)',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/What+is+Escrow_Spanish.pdf'
    },
    {
        divisionType: 'Florida',
        resourceType: 'Rosters',
        key: uniqueID(),
        title: 'Palm Beach Team',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/rosters/Palm-Beach-FL-Team-Roster-080621.pdf'
    },
    {
        divisionType: 'Florida',
        resourceType: 'Rosters',
        key: uniqueID(),
        title: 'Stuart Team',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/rosters/Stuart-FL-Team-Roster-080621.pdf'
    },
    {
        divisionType: 'Illinois',
        resourceType: 'Rosters',
        key: uniqueID(),
        title: 'Illinois Team',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/rosters/IL-Team-Roster-081021.pdf'
    },
    {
        divisionType: 'Illinois',
        resourceType: 'Helpful Guides',
        key: uniqueID(),
        title: 'Illinois Residential Title Insurance Rates & Charges',
        link: 'https://titlefy-resources.s3-us-west-2.amazonaws.com/truly/helpful-guides/Illinois_Residential_Title_Insurance_Rates___Charges.pdf'
    },
    {
        divisionType: "Illinois",
        resourceType: "Helpful Guides",
        key: uniqueID(),
        title: "Illinois Closing Locations",
        link: "https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/helpful-guides/IL-Closing-Locations-111721.pdf"
    },
    {
        divisionType: 'Arizona',
        resourceType: 'Rosters',
        key: uniqueID(),
        title: 'Arizona Team',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/rosters/AZ-Team-Roster-080621.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Allen Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Allen.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Argyle Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Argyle.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Celina Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Celina.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Cleburne Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Cleburne.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Colleyville Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Colleyville.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Conroe Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Conroe.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Dallas Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Dallas.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Flower Mound Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Flower+Mound.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Fort Worth Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Fort+Worth.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Frisco Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Frisco.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Grapevine Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Grapevine.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Haslet Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Haslet.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Highland Village Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Highland-Village.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Justin Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Justin.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Keller Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Keller.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Lewisville Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Lewisville.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Magnolia Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Magnolia.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Mckinney Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Mckinney.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Plano Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Plano.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Prosper Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Prosper.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Roanoke Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Roanoke.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Southlake Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Southlake.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Spring Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Spring.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'The Woodlands Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_The-Woodlands.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Trophy Club Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX_Profile_Trophy-Club.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Canton Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX-Profile-Canton-102721.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Ennis Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX-Profile-Ennis-102821.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Gilmer Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX-Profile-Gilmer-102721.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Kilgore Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX-Profile-Kilgore-102721.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Lindale Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX-Profile-Lindale-102721.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Longview Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX-Profile-Longview-102721.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Midlothian Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX-Profile-Midlothian-102821.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Tyler Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX-Profile-Tyler-102721.pdf'
    },
    {
        divisionType: 'Texas',
        resourceType: 'City Profiles',
        key: uniqueID(),
        title: 'Waxahachie Profile',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/city-profiles/TX-Profile-Waxahachie-102821.pdf'
    },
    {
        divisionType: 'All States',
        resourceType: 'Applications',
        key: uniqueID(),
        title: 'Truly Premiere Mobile App',
        link: 'https://titlefy-resources.s3.us-west-2.amazonaws.com/truly/applications/Truly_Premier_FlueidPro_Mobile_App-032521.pdf'
    },
]
