/**
 * Wraps analytics call in a null check
 * @types/gtag doesn't support passing spread args, so ts check is bypassed
 */
// @ts-ignore

export const sendToAnalytics: Gtag.Gtag = (...args) => {

  const windowAny: any = window;

    if (typeof windowAny.gtag === "function") {
      // @ts-ignore
      gtag(...args);
    }
  };
  
  export const isDev = process.env.NODE_ENV === "development";
  