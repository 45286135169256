

import * as React from 'react';
import { DivisionType, ResourceType, divisionOptions, resourceOptions } from '../../types/resources';

interface Props {
    handleDivisionClick: (division: DivisionType) => void;
    handleResourceOptionClick: (resource: ResourceType) => void;
    division: DivisionType;
    resource: ResourceType;
}

interface State {
    divisionOpen: boolean;
    resourceOpen: boolean;
}

class DropDownsContainer extends React.Component<Props, State> {

    state = {
        divisionOpen: false,
        resourceOpen: false
    }

    private divisionDropdownRef = React.createRef<HTMLDivElement>();
    private resourceDropdownRef = React.createRef<HTMLDivElement>();
    private divTitleRef = React.createRef<HTMLDivElement>();
    private resourceTitleRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutsideDropdown);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsideDropdown);
    }

    handleClickOutsideDropdown = (e: any) => {

        const divisionDropdownRef = this.divisionDropdownRef.current;
        const divTitleRef = this.divTitleRef.current;

        const resourcesDropdownRef = this.resourceDropdownRef.current;
        const resourceTitleRef = this.resourceTitleRef.current;

        const { divisionOpen, resourceOpen } = this.state;

        if (divisionDropdownRef && divisionOpen && !divisionDropdownRef.contains(e.target) && divTitleRef && !divTitleRef.contains(e.target)) {
            this.setState({ divisionOpen: false });
        }

        if (resourcesDropdownRef && resourceOpen && !resourcesDropdownRef.contains(e.target) && resourceTitleRef && !resourceTitleRef.contains(e.target)) {
            this.setState({ resourceOpen: false });
        }
    }

    renderDivisionOptions = (options: DivisionType[], handleSelection: (option: DivisionType) => void, open: boolean) => {

        const { division } = this.props;

        return (
            <div className={`dropdown-options ${open ? 'open' : 'closed'}`} id='div-options' ref={this.divisionDropdownRef}>
                {options.map((option: DivisionType, index: number) => <div key={index} className={`option ${division === option ? 'active' : ''}`} id={`option-row-${index}`} onClick={() => {
                    handleSelection(option);
                    this.closeDivisionOptions();
                }}><div className='option-txt'>{option.toUpperCase()}</div></div>)}
            </div>
        );
    }

    //renderDropDownValues = (resourceOptions: any) => {
    //    const { division } = this.props;
    //    if (division === 'All States') {
    //        return resourceOptions;
    //    } else if (division === 'Texas') {
    //        resourceOptions = ['All Resources', 'Flyers', 'Helpful Guides'];
    //        return resourceOptions;
    //    } else if (division === 'Utah') {
    //        resourceOptions = ['All Resources', 'Flyers', 'Helpful Guides'];
    //        return resourceOptions;
    //    }
    //}

    renderResourceOptions = (options: ResourceType[], handleSelection: (option: ResourceType) => void, open: boolean) => {

        const { resource } = this.props;

        return (
            <div className={`dropdown-options ${open ? 'open' : 'closed'}`} id='resources-options' ref={this.resourceDropdownRef}>
                {options.map((option: ResourceType, index: number) => <div key={index} className={`option ${resource === option ? 'active' : ''}`} onClick={() => {
                    handleSelection(option)
                    this.closeResourceOptions();
                }}><div className='option-txt'>{option.toUpperCase()}</div></div>)}
            </div>
        );
    }

    closeDivisionOptions = () => this.setState({ divisionOpen: false });
    closeResourceOptions = () => this.setState({ resourceOpen: false });

    render() {

        const { handleResourceOptionClick, division, resource, handleDivisionClick } = this.props;
        const { divisionOpen, resourceOpen } = this.state;

        return (
            <div className='width-container'>
                <div className='dropdown-container'>
                    <div className='dropdown'>
                        <div className='title-btn' ref={this.divTitleRef} id='div-title' onClick={() => {

                            this.setState({ divisionOpen: !divisionOpen });

                        }}>
                            <div className='division title' id='div-title-txt'>{division}</div>
                            <div className='down-arrow' id='div-title-arrow' />
                        </div>
                        {
                            this.renderDivisionOptions(divisionOptions, handleDivisionClick, divisionOpen)
                        }
                    </div>
                    <div className='dropdown'>
                        <div className='title-btn' id='rsce-title' ref={this.resourceTitleRef} onClick={() => {
                            this.setState({ resourceOpen: !resourceOpen })
                        }}>
                            <div className='resource title' id='rsce-title-txt'>{resource}</div>
                            <div className='down-arrow' id='rsce-title-arrow' />
                        </div>
                        {
                            this.renderResourceOptions(resourceOptions, handleResourceOptionClick, resourceOpen)
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default DropDownsContainer;