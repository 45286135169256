import * as React from 'react';
import { Transition } from 'semantic-ui-react';
import Slider from 'react-slick';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// arrow components for the <Slider />
const NextArrow = (props: any) => {
	const { className, onClick } = props;
	return (
		<div
			className={className}
			onClick={onClick}
		/>
	)
}

const PrevArrow = (props: any) => {
	const { className, onClick } = props;
	return (
		<div
			className={className}
			onClick={onClick}
		/>
	)
}


interface Employee {
	imgURL: string;
	name: string;
	position: string;
	linkedInURL: string;
}

class OurTeamSection extends React.Component<RouteComponentProps> {

	sendContactData = () => {
		this.props.history.push({
			pathname: '/contact-us',
			search: "topic=I’m interested in joining the Truly Team"
		})
	}

	renderEmployeeCard = (employee: Employee, index: number) => {

		const firstName = employee.name.split(" ")[0];
		const lastName = employee.name.split(" ")[1];

		return (
			<a target="_blank" href={employee.linkedInURL} key={index}>
				<Transition animation="fade up" duration={2500}>
					<div className="employee-card-container" key={index}>
						<div className="img-container">
							<img src={employee.imgURL} alt={firstName + " " + lastName + " " + `Truly Title`} className="employee-image" />
						</div>
						<div className="employee-info-container">
							<h4 className="employee-name">{employee.name}</h4>
							<p className="employee-position">{employee.position}</p>
						</div>
					</div>
				</Transition>
			</a >
		);
	}

	render() {

		const employees: Employee[] = [
			{
				imgURL: "employeePortraits/MikeT.jpg",
				name: "Michael Tafoya",
				position: "CEO",
				linkedInURL: "https://www.linkedin.com/in/michael-tafoya-70b39a7/"
			},
			{
				imgURL: "employeePortraits/mike-kirby2@2x.jpg",
				name: "Mike Kirby",
				position: "President/COO",
				linkedInURL: "https://www.linkedin.com/in/mike-kirby-38727628/"
			},
			{
				imgURL: "employeePortraits/michael-smith@2x.png",
				name: "Mike Smith",
				position: "Senior Vice President/General Counsel",
				linkedInURL: "https://www.linkedin.com/in/mike-smith-a9070224/"
			},
			{
				imgURL: "employeePortraits/GrahamH.jpg",
				name: "Graham Hanks",
				position: "President - Texas",
				linkedInURL: "https://www.linkedin.com/in/graham-hanks-a5191a34/"
			},
			{
				imgURL: "employeePortraits/crystal-bond@2x.png",
				name: "Crystal Bond",
				position: "President - Utah",
				linkedInURL: "https://www.linkedin.com/in/crystal-bond-270648183/"
			},
			{
				imgURL: "employeePortraits/Rob_Whalen_BK.jpg",
				name: "Rob Wahlen",
				position: "President – Illinois",
				linkedInURL: "https://www.linkedin.com/in/rob-wahlen/"
			},
			{
				imgURL: "employeePortraits/KerrieD.jpg",
				name: "Kerri Damone",
				position: "President – Florida",
				linkedInURL: "https://www.linkedin.com/in/kerri-damone-9233a21b9/"
			},
			{
				imgURL: "employeePortraits/sharon-grannis-AZ.jpg",
				name: "Sharon Grannis",
				position: "President – Arizona",
				linkedInURL: "https://www.linkedin.com/in/sharon-grannis-a8686a20/"
			},
		]
		const sliderSettings = {
			dots: true,
			infinite: true,
			speed: 750,
			nextArrow: <NextArrow />,
			prevArrow: <PrevArrow />
		}

		return (
			<div className="our-team-container">
				<div className="red-block-container">
					<div className="red-block" />
				</div>
				<div className="our-team-content">
					<div className="our-team-header-container">
						<h2 className="header-lighter">Our</h2>
						<h2>Team</h2>
					</div>
					<div>
						<div className='employee-grid-container'>
							{employees.map((employee, index) => this.renderEmployeeCard(employee, index))}
							<div className='were-hiring-container-wrapper'>
								<Transition animation="fade up" duration={2500}>
									<div className="were-hiring-container">
										<h3>We're hiring!</h3>
										<div onClick={this.sendContactData}>
											<div className="contact-us-btn">
												Contact Us
											</div>
										</div>
									</div>
								</Transition>
							</div>
						</div>
					</div>
					<div className='hide-slider visibility-employee-cards-container '>
						<Slider {...sliderSettings}>
							{employees.map((employee, index) => this.renderEmployeeCard(employee, index))}
							<div className='were-hiring-container-wrapper'>
								<Transition animation="fade up" duration={2500}>
									<div className="were-hiring-container">
										<h3>We're hiring!</h3>
										<div onClick={this.sendContactData}>
											<div className="contact-us-btn">
												Contact Us
											</div>
										</div>
									</div>
								</Transition>
							</div>
						</Slider>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(OurTeamSection);