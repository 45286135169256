import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';

// attempted to use this State interface to setup an index signature
// to use the abstracted bracket notation/string interpolation (seen at bottom of handleUpdate()) for handling visibility
// updates of different elements...hasn't worked yet though.

// interface State {
//	[key: string]: boolean;
// }

interface State {
	value1Visible: boolean;
	value2Visible: boolean;
	value3Visible: boolean;
	value4Visible: boolean;
}

class CoreValuesSection extends React.Component<any, State> {

	state = {
		value1Visible: false,
		value2Visible: false,
		value3Visible: false,
		value4Visible: false,
	}

	handleUpdate = (e: any, data: any, valueName: string) => {
		switch (valueName) {
			case "value1Visible":
				if (!this.state.value1Visible && data.calculations.topVisible) {
					this.setState({ value1Visible: true })
					return "";
				}
				return "";
			case "value2Visible":
				if (!this.state.value2Visible && data.calculations.topVisible) {
					this.setState({ value2Visible: true })
					return "";
				}
				return "";
			case "value3Visible":
				if (!this.state.value3Visible && data.calculations.topVisible) {
					this.setState({ value3Visible: true })
					return "";
				}
				return "";
			case "value4Visible":
				if (!this.state.value4Visible && data.calculations.topVisible) {
					this.setState({ value4Visible: true })
					return "";
				}
				return "";
			default:
				return {}
		}
		//if (!this.state[`${valueName}Visible`]) {
		//	this.setState({ [`${valueName}Visible`]: true })
		//}
	}

	render() {

		const { value1Visible, value2Visible, value3Visible, value4Visible } = this.state;

		return (
			<div className="core-values-container" id="core-values">
				<div className="core-values-content">
					<div className="core-value-row">
						<div className="values-header-container" >
							<h2 className="lighter-header">Our</h2>
							<h2>Core Values</h2>
						</div>						
					</div>		
					<div className="core-value-row">
                        <Visibility fireOnMount onUpdate={(e, data, valueName = "value1Visible") => this.handleUpdate(e, data, valueName)} className="visibility-value-container">
							<Transition visible={value1Visible} animation="fade up" duration={2500}>
								<div className="value-content">
									<h3>We TRULY Care</h3>
									<p className="value-paragraph">Our employees are the number one priority at Truly and we care about the culture we build, the environment we create and the opportunities for growth we present.</p>
								</div>
							</Transition>
						</Visibility>						
					</div>
					<div className="core-value-row">
                        <Visibility fireOnMount onUpdate={(e, data, valueName = "value2Visible") => this.handleUpdate(e, data, valueName)} className="visibility-value-container">
							<Transition visible={value2Visible} animation="fade up" duration={2500}>
								<div className="value-content">
									<h3>Drive Innovation</h3>
									<p className="value-paragraph">We are passionate about the settlement services industry. We continue to strive to make a difference by challenging the normal and pushing the team to deliver the best user experience possible.</p>
								</div>
							</Transition>
						</Visibility>					
					</div>
					<div className="core-value-row">
                        <Visibility fireOnMount onUpdate={(e, data, valueName = "value3Visible") => this.handleUpdate(e, data, valueName)} className="visibility-value-container">
							<Transition visible={value3Visible} animation="fade up" duration={2500}>
								<div className="value-content">
									<h3>Transparent</h3>
									<p className="value-paragraph">Knowing what is going on makes us all feel comfortable and empowered. We strive to create transparency into the transactions, deliver information fast and secure and by utilizing the latest technology.</p>
								</div>
							</Transition>
						</Visibility>						
					</div>
					<div className="core-value-row">
                        <Visibility fireOnMount onUpdate={(e, data, valueName = "value4Visible") => this.handleUpdate(e, data, valueName)} className="visibility-value-container">
							<Transition visible={value4Visible} animation="fade up" duration={2500}>
								<div className="value-content">
									<h3>Entrepreneurs for Life!</h3>
									<p className="value-paragraph">There is an entrepreneur in all of us!! We take pride in what we build, create and change. We move fast and we own the outcome and we get it done.</p>
								</div>
							</Transition>
						</Visibility>						
					</div>
				</div>
			</div>
		);
	}
}

export default CoreValuesSection;