import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';

interface State {
	elementVisible: boolean;
}

class ClosingServicesSection extends React.Component<any, State> {

    state = {
        elementVisible: false
    }

    handleVisibilityUpdate = (e: any, data: any) => {

		const { elementVisible } = this.state;
		const { topVisible, bottomVisible } = data.calculations;

		if (!elementVisible && (topVisible || bottomVisible)) {
			this.setState({ elementVisible: true })
		}
    }

    render() {

		const { elementVisible } = this.state;

		return (
			<div className="closing-service-main-section" id="closing-services">
				<div className="closing-service-content-container">
                    <Visibility fireOnMount onUpdate={this.handleVisibilityUpdate} style={{ minHeight: "100px" }} className="closing-service-visibility-container">
						<Transition visible={elementVisible} animation="fade up" duration={2500}>
							<div className="image-section">
								<div className="closing-img"></div>
							</div>
						</Transition>
					</Visibility>
					<div className="services-closing-content">
						<div className="content-text-card">
							<div className="closing-main-header-container" >
								<h2>Closing</h2>
								<div>
									<h2 className="h2-lighter">Services</h2>
								</div>
								<div className="closing-paragraph">
									At Truly, we understand the complexities of title and closing.  More importantly, we know that the last place you can afford to make mistakes is at the goal line when it’s time for funding and signatures.  Our title and closing experts, internal quality control processes and technology solutions help ensure the closing proceeds as smooth as possible.
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
        );
    }
}


export default ClosingServicesSection;