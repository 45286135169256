import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';

interface State {
	elementVisible: boolean;
}

class ServicesTitleInsurance extends React.Component<any, State> {

    state = {
        elementVisible: false
    }

    handleVisibilityUpdate = (e: any, data: any) => {

		const { elementVisible } = this.state;
		const { topVisible, bottomVisible } = data.calculations;

		if (!elementVisible && (topVisible || bottomVisible)) {
			this.setState({ elementVisible: true })
		}
    }

	render() {

        const { elementVisible } = this.state;

		return (
            <div className="our-insurance-container">
                <div className="our-insurance-content">
                    <div className="tryInsContent-01">
                        <div className="insurance-header-container" >
                            <div className="insurance-main-header-container" >
                                <h2>Title</h2>
                                <div>
                                    <h2 className="h2-lighter">Insurance</h2>
                                </div>
                            </div>
                            <div className="offers-red-block-underline" />
                        </div>
                        <div className="insurance-paragraph-container">
                            <Visibility fireOnMount onUpdate={this.handleVisibilityUpdate} className="visibility-paragraph-container">
                                <Transition visible={elementVisible} animation="fade up" duration={2500}>
									<p className="insurance-small-paragraph">TRULY supports clients across the wide spectrum of the financial services industry with a full complement of title services.</p>
                                </Transition>
                            </Visibility>                           
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default ServicesTitleInsurance;