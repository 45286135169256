import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';

interface State {
	elementVisible: boolean;
}

class SupportiveTechSection extends React.Component<any, State> {

	state = {
		elementVisible: false
	}

	handleVisibilityUpdate = (e: any, data: any) => {

		const { elementVisible } = this.state;
		const { topVisible, bottomVisible } = data.calculations;

		if (!elementVisible && (topVisible || bottomVisible)) {
			this.setState({ elementVisible: true })
		}
	}

	render() {

		const { elementVisible } = this.state;

		return (
				<div className="supportive-tech-container" id="supportive-tech-container">					
					<div className="supportive-tech-content">
						<div className="tech-text-background-container">
							<div className="content">
								<div className="tech-text-container">
									<div className="text-content">
										<div className="header-container">
											<h2>Supportive</h2>
											<h2 className="title-bolder">Tech</h2>
										</div>
										<Visibility fireOnMount onUpdate={this.handleVisibilityUpdate} style={{ height: "100%" }}>
											<p>From the moment you start working with Truly Title, you’ll have access to a comprehensive toolset to assist you throughout the entire real estate transaction.</p>
										</Visibility>
									</div>
								</div>
								<div className="img-container" />	
								<Transition visible={elementVisible} animation="fade up" duration={2500}>
									<div className="tech-card-container">
										<div className="tech-card-flex-display">
											<div className="tech-card-content">
												<div className="tech-tools-icon" />
												<h3>THE RIGHT TOOLS FOR THE JOB</h3>
												<ul>
													<li>Hot property search</li>
													<li>Comprehensive property profile</li>
													<li>Geo-farming</li>
													<li>Simplified ordering</li>
													<li>Instant quotes</li>
													<li>Open order</li>
												</ul>
											</div>
										</div>									
									</div>
								</Transition>
							</div>
						</div>
					</div>	
				</div>
		);
	}
}

export default SupportiveTechSection;
