
/// <reference types="@types/googlemaps" />
import * as React from 'react';
import {ReactComponent as MapPlaceholder} from "./mapPlaceholder.svg";

interface Props {
    locationId: string;
    coordinates: { lat: number, lng: number };
    zoom: number;
    mapVisible: boolean;
    showMapPlaceholder?: boolean;
}

class MapContainer extends React.Component<Props, any> {

    private googleMapRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        const { mapVisible, showMapPlaceholder} = this.props;

        if (mapVisible && !showMapPlaceholder) {
            this.initMap();
        }
    }

    initMap() {

        const { zoom, coordinates } = this.props;

        const googleWindow: any = window;
        const googleAPI = googleWindow.google as typeof google;

        const mapRef = this.googleMapRef.current;

        if (mapRef && googleAPI) {
            const map = new googleAPI.maps.Map(mapRef, {
                zoom: zoom,
                center: coordinates,
            });

            const locationIcon = {
                url: "icon-office-location@2x.png",
                scaledSize: new googleAPI.maps.Size(120, 65)
            };

            new googleAPI.maps.Marker({
                position: coordinates,
                icon: locationIcon,
                map: map
            });

        }

    }

    render() {

        const { locationId, showMapPlaceholder } = this.props;

        return (
            !showMapPlaceholder ? (
                <div className='google-map' id={`google-map-${locationId}`} ref={this.googleMapRef} />
            ) : (
                <div className='google-map map-placeholder'>
                    <MapPlaceholder/>
                </div>
            )
        );
    }
}

export default MapContainer;