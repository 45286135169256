import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';

interface State {
	elementVisible: boolean;
}

class OurMissionSection extends React.Component<any, State> {

	state = {		
		elementVisible: false
	}

	handleVisibilityUpdate = (e: any, data: any) => {

		const { elementVisible } = this.state;		
		const { topVisible, bottomVisible } = data.calculations;

		if (!elementVisible && (topVisible || bottomVisible)) {
			this.setState({ elementVisible: true })
		}
	}

	render() {

		const { elementVisible } = this.state;

		return (
			<div className="our-mission-container">
				<div className="our-mission-content" >
					<div className="mission-header-container" >
						<div className="mission-main-header-container" >
							<h2 className="h2-lighter">Our</h2>
							<h2>Mission</h2>
						</div>
						<div className="mission-red-block-underline" />
						<Visibility fireOnMount onUpdate={this.handleVisibilityUpdate} className="visibility-paragraph-container">
							<Transition visible={elementVisible} animation="fade up" duration={2500}>
								<p className="mission-large-paragraph">Empower our employees to create an awesome client experience and layer on industry changing technology. We don’t keep pace, we set the pace!</p>
							</Transition>
						</Visibility>
					</div>
					<div className="mission-paragraph-container">
						<div className="mission-paragraph-content" >
							<p className="mission-paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut pretium pretium tempor. Ut eget imperdiet neque. In volutpat ante semper diam molestie, et aliquam erat laoreet. Sed sit amet arcu aliquet, molestie justo at, auctor nunc.  Ut eget imperdiet neque. </p>
							<p className="mission-paragraph">In volutpat ante semper diam molestie, et aliquam erat laoreet. Sed sit amet arcu aliquet, molestie justo at, auctor nunc.</p>
						</div>						
					</div>
				</div>
			</div>
		);
	}
}

export default OurMissionSection;