
import * as React from 'react';

interface Props {
    url: string;
}

class DownloadBtn extends React.Component<Props, any> {

    render() {
        const { url } = this.props;

        return (
            <React.Fragment>
                <div className="btn btn-medium btn-tan" >
                    <a href={url} target="_blank"
                        className='btn btn-medium btn-tan'>
                        Preview
                    </a>
                </div> 
            </React.Fragment>
        );
    }
}

export default DownloadBtn;