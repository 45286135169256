import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';

interface State {
	elementVisible: boolean;
}

class IntegratedPlatformSection extends React.Component<any, State> {

	state = {
		elementVisible: false
	}

	handleVisibilityUpdate = (e: any, data: any) => {

		const { elementVisible } = this.state;
		const { topVisible, bottomVisible } = data.calculations;

		if (!elementVisible && (topVisible || bottomVisible)) {
			this.setState({ elementVisible: true })
		}
	}

	render() {

		const { elementVisible } = this.state;

		return (
			<div className="integrated-platform-container" id="integrated-platform-container">
				<div className="integrated-platform-content">
					<div className="platform-text-background-container">
						<div className="content">
							<div className="text-content-container">
								<div className="text-content">
									<h2>Integrated</h2>
									<h2 className="title-bolder">Platform</h2>
									<p>Closing on a property involves a lot of professionals working together. Lenders, agents, and consumers all have to be on the same page. We believe a platform that connects will result in the most efficient transactions. That is why TRULY’s platform facilitates collaboration and transparency in real-time.</p>
								</div>
							</div>
							<Visibility fireOnMount onUpdate={this.handleVisibilityUpdate} className="visibility-img-container">
								<Transition visible={elementVisible} animation="fade up" duration={2500}>
									<div className="img-container" />							
								</Transition>						
							</Visibility>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default IntegratedPlatformSection;