import * as React from 'react';
import NavBar from '../navBar';
import OurOffersSection from './ourOffersSection';
import ServicesTitleInsurance from './servicesTitleInsurance';
import OffersCardSection from './offersCardSection';
import ClosingServicesSection from './closingServicesSection';
import CustomersServicesSection from './customersServicesSection';
import Footer from '../footer';

interface Props {
    openSignUpForm: () => void;
}

class ServicesPage extends React.Component<Props, any> {

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }



    render() {
        const { openSignUpForm } = this.props;
        return (
            <div>
                <div>
                    <div className="services-page-container">
                        <div className="navbar-hero-container">
                            <NavBar location="about-page" openSignUpForm={openSignUpForm} />
                            <div className="services-page-hero-container">
                                <div className="hero-text-container">
                                    <h1>Services</h1>
                                    <h1 className="title-lighter">You can count on</h1>
                                </div>
                            </div>
                        </div>
                        <div className="services-content-main">
                            <OurOffersSection />
                            <div className="title-insurance-section-container">
                                <div className="title-insurance-content">
                                    <ServicesTitleInsurance />
                                    <div className="services-title-photo" id="services-title-insurance" />
                                    <div className="card-container-main">
                                        <OffersCardSection />
                                    </div>
                                </div>
                            </div>
                            <ClosingServicesSection />
                            <CustomersServicesSection />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default ServicesPage;