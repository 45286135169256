import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';
import { Transition } from 'semantic-ui-react';
import AppConfig from '../appConfig';
import { TrulyLicensedState } from '../types/officeLocations';

interface State {
    previousScrollY: number,
    scrollingUp: boolean;
    mobileMenuOpen: boolean;
    moreOptionsOpen: boolean;
    mobileMoreOptionsOpen: boolean;
}

interface Props {
    openSignUpForm: () => void;
    location: string;
    userState?: TrulyLicensedState | null;
}

class NavBar extends React.Component<Props, State> {

    state = {
        previousScrollY: 0,
        scrollingUp: false,
        mobileMenuOpen: false,
        moreOptionsOpen: false,
        mobileMoreOptionsOpen: false
    }

    private dropdownRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        document.addEventListener('mousedown', this.handleClickOutsideDropdown);

    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        document.removeEventListener('mousedown', this.handleClickOutsideDropdown);
    }

    handleScroll = () => {
        const { previousScrollY } = this.state;

        if (window.scrollY < previousScrollY) {
            this.setState({
                previousScrollY: window.scrollY,
                scrollingUp: true,
                moreOptionsOpen: false
            });
        } else {
            this.setState({
                previousScrollY: window.scrollY,
                scrollingUp: false,
                moreOptionsOpen: false
            });
        }
    }

    handleClickOutsideDropdown = (e: any) => {

        const dropdownRef = this.dropdownRef.current;

        // KEEP THESE COMMENTED OUT PARTS FOR FUTURE TESTING TO TRY AND RESOLVE THE .CONTAINS() BUGGYNESS
        // if (dropdownRef) {            
        //console.log({ dropdownRef })
        //console.log("e.target: ", e.target)
        // console.log('e.target.classList: ', e.target.classList)
        // console.log('classList contains more-menu-option: ', e.target.classList.contains('more-menu-option'))
        // console.log('dropdownRef does NOT contain target: ', !dropdownRef.contains(e.target))
        // }

        // For some reason .contains() doesn't consistently return true for a grandchild of the dropdownRef as it should,
        //  which ends up closing the moreOptionsMenu before the clicked link can route
        //if (dropdownRef && !dropdownRef.contains(e.target)) {            
        //    this.setState({ moreOptionsOpen: false }, () => console.log('moreOptions set false by ClickOUtsideHandler'));
        //}

        // Instead of the above .contains() approach, using more specific check on classList of e.target...this works consistently
        if (!e.target.classList.contains('more-menu-option') && !e.target.classList.contains('more-btn')) {
            this.setState({ moreOptionsOpen: false });
        }


    }

    openMobileMenu = () => {
        this.setState({ mobileMenuOpen: true });
    }

    closeMobileMenu = () => {
        this.setState({ mobileMenuOpen: false, mobileMoreOptionsOpen: false });
    }

    locationValidation = (linkName: string) => {
        // if user clicks the link to the page they're currently on, the mobile menu should close
        const pathname = window.location.pathname;

        if (linkName === pathname) this.closeMobileMenu();

    }

    renderHamburger = () => {

        const { location } = this.props;

        return (
            <div className="navbar-hamburger" onClick={this.openMobileMenu}>
                <div className={`hamburger-bar-${location}`} />
                <div className={`hamburger-bar-${location}`} />
                <div className={`hamburger-bar-${location}`} />
            </div>
        );
    }

    renderMobileMenu = () => {

        const { openSignUpForm, location } = this.props;
        const { mobileMoreOptionsOpen } = this.state;

        return (
            <Transition visible={this.state.mobileMenuOpen} animation="fade" duration={500}>
                <div className="navbar-mobile-menu-open">
                    <div className='navbar-container'>
                        <div className='navbar-content'>
                            <div className='navbar-logo-section-resources-page red-logo' onClick={() => this.closeMobileMenu()}>
                                <NavLink to='/' exact id='mob-logo-link'>

                                </NavLink>
                            </div>

                            <div className="close-mobile-menu-icon" onClick={this.closeMobileMenu}>&times;</div>
                        </div>
                    </div>
                    <div className="navbar-mobile-menu-item-container">
                        <div className="navbar-menu-item menu-item-margin" onClick={() => this.locationValidation("/")}>
                            <NavLink to="/" exact>
                                HOME
							</NavLink>
                        </div>
                        <div className="navbar-menu-item menu-item-margin" onClick={() => this.locationValidation("/services")}>
                            <NavLink to="/services" >
                                SERVICES
							</NavLink>
                        </div>
                        <div className="navbar-menu-item menu-item-margin" onClick={() => this.locationValidation("/technology")}>
                            <NavLink to="/technology">
                                TECHNOLOGY
							</NavLink>
                        </div>

                        {/*<div className="navbar-menu-item menu-item-margin get-quote-link">
							<a
								href="https://www.titlecapture.com/app-new/account/form/quick-quote?c=trulytitle"
								target="_blank"								
							>
								GET A QUOTE
							</a>
						</div>*/}

                        <div className="navbar-menu-item menu-item-margin" onClick={() => this.locationValidation("/about")}>
                            <NavLink to="/about">
                                ABOUT
							</NavLink>
                        </div>
                        <NavLink to="/contact-us" className="navbar-menu-item menu-item-margin">
                            <div>
                                CONTACT US
							</div>
                        </NavLink>

                        <div className="navbar-menu-item menu-item-margin" onClick={() => this.locationValidation("/locations")}>
                            <NavLink to="/locations">
                                LOCATIONS
							</NavLink>
                        </div>

                        <div className="navbar-menu-item menu-item-margin" onClick={() => this.locationValidation("/resources")}>
                            <NavLink to="/resources">
                                RESOURCES
							</NavLink>
                        </div>

                        {/* <div className='navbar-menu-item menu-item-margin' onClick={() => this.setState({ mobileMoreOptionsOpen: !mobileMoreOptionsOpen })}>
                            <div
                                className={`more-btn mobile ${mobileMoreOptionsOpen ? 'up-arrow' : 'down-arrow'}`}                                
                                id='mob-more-btn'                                
                            >
                                MORE
                            </div>
                        </div> */}

                        <div className={`mob navbar-menu-item menu-item-margin ${mobileMoreOptionsOpen ? 'open' : 'closed'}`}>
                            <a
                                href="https://www.titlecapture.com/app-new/account/form/quick-quote?c=trulytitle"
                                target="_blank"
                            >
                                GET A QUOTE
                            </a>
                        </div>

                        <div className={`mob navbar-menu-item menu-item-margin ${mobileMoreOptionsOpen ? 'open' : 'closed'}`} onClick={() => this.locationValidation('/resources')}>
                            <NavLink to='/resources'>
                                RESOURCES
                            </NavLink>
                        </div>

                        <div className={`mob navbar-menu-item menu-item-margin ${mobileMoreOptionsOpen ? 'open' : 'closed'}`} onClick={() => this.locationValidation('/locations')}>
                            <NavLink to="/locations">
                                LOCATIONS
                            </NavLink>
                        </div>

                        {/*<div className='more-btns mobile'>
                            <div className='navbar-menu-item menu-item-margin' onClick={() => this.setState({ mobileMoreOptionsOpen: !mobileMoreOptionsOpen })}>
                                <div
                                    className={`more-btn mobile ${mobileMoreOptionsOpen ? 'up-arrow' : 'down-arrow'}`}
                                    style={mobileMoreOptionsOpen ? { marginBottom: '4px' } : {}}
                                    id='mob-more-btn'
                                >
                                    MORE
                                </div>
                            </div>
                            {this.renderMobileMoreMenu()}
                        </div> */}

                        <a href="https://www.titlecapture.com/app-new/account/form/quick-quote?c=trulytitle"
                            target="_blank" className='menu-item-margin'>
                            <div className="btn btn-medium btn-transparent">
                                GET A QUOTE
						    </div>
                        </a>
                        <div className="navbarLineSeperatorMob"></div>
                        <a href={this.titlefySignInURL()}>
                            <div className={`btn signIn`}>
                                SIGN IN
                                </div>
                        </a>
                        <NavLink to="/signup" className="signup-btn btn btn-sign-up btn-red menu-item-margin" onClick={() => this.closeMobileMenu()}>
                            SIGN UP FOR FLUEID PRO
						</NavLink>
                    </div>
                </div>
            </Transition>
        );
    }

    renderMobileMoreMenu = () => {
        const { mobileMoreOptionsOpen } = this.state;
        const { location } = this.props;
        return (
            <div
                className={`mobile-more-menu ${mobileMoreOptionsOpen ? 'open' : 'closed'}`}
                id='mob-more-menu'
                onClick={() => this.setState({ mobileMoreOptionsOpen: false })}

            >
                {/* <div className='more-menu-title'>MORE</div> */}
                <div className='options'>
                    <a
                        href="https://www.titlecapture.com/app-new/account/form/quick-quote?c=trulytitle"
                        target="_blank"
                    >
                        <div className='more-menu-option'>
                            GET A QUOTE
                        </div>
                    </a>
                    <NavLink to='/resources'>
                        <div className={`more-menu-option bordered ${location === 'resources-page' ? 'active' : ''}`} onClick={() => this.locationValidation('/resources')}>
                            RESOURCES
                        </div>
                    </NavLink>
                    <NavLink to="/locations">
                        <div className={`more-menu-option ${location === 'locations-page' ? 'active' : ''}`} onClick={() => this.locationValidation('/locations')}>
                            LOCATIONS
                        </div>
                    </NavLink>
                </div>
            </div>
        );
    }

    renderMoreOptionsMenu = () => {

        const { moreOptionsOpen } = this.state;
        const { location } = this.props;

        return (
            <div
                className={`more-menu-container ${moreOptionsOpen ? 'open' : 'closed'}`}
                id='nav-more-menu'
                ref={this.dropdownRef}
                onClick={() => this.setState({ moreOptionsOpen: false })}
            >
                <div
                    className='more-menu-title'
                >
                    MORE
                </div>
                <div className='options'>
                    <NavLink to="/contact-us" className='more-menu-option' style={{ cursor: 'pointer' }}>
                        CONTACT US
                    </NavLink>
                    <NavLink to='/resources'>
                        <div className={`more-menu-option bordered ${location === 'resources-page' ? 'active' : ''}`}>
                            RESOURCES
                        </div>
                    </NavLink>
                    <NavLink to="/locations">
                        <div className={`more-menu-option ${location === 'locations-page' ? 'active' : ''}`}>
                            LOCATIONS
                        </div>
                    </NavLink>
                </div>
            </div>
        );
    }

    titlefySignInURL = () => {

        const environment = AppConfig.environment();
        let titlefyURL = '';
        const pathName: string = '/auth/sign-in/truly';

        switch (environment) {
            case ('development'):
                titlefyURL = `https://localhost:44334${pathName}`;
                break;
            case ('staging'):
                titlefyURL = `https://staging.pro.flueid.com${pathName}`;
                break;
            case ('production'):
                titlefyURL = `https://pro.flueid.com${pathName}`;
                break;
        }

        return titlefyURL;

    }

    render() {

        // determines if the scrolling navbar is visible and at what point to fade it out before the top of the page
        let navBarCutoffHeight;
        if (window.innerWidth < 992) {
            navBarCutoffHeight = 150;
        } else {
            navBarCutoffHeight = 300;
        }

        const { scrollingUp, previousScrollY, moreOptionsOpen } = this.state;


        const scrollNavBarVisible = scrollingUp && previousScrollY > navBarCutoffHeight;
        const { location, openSignUpForm, userState } = this.props;
        const { mobileMenuOpen } = this.state;

        return (
            <React.Fragment>
                <div className="navbar-container" id={`${location}-navbar-container`}>
                    <div className="navbar-content" id={`${location}-navbar-content`}>
                        <div className={`navbar-logo-section-${location} ${userState === 'IL' ? userState : 'base'}`}>
                            <NavLink to="/" className="logo-link" />
                        </div>
                        <div className="navbar-menu-item-container" id={`${location}-menu-item-container`}>
                            <div className="navbar-menu-item">
                                <NavLink to="/services" className={`${location}-link`} >
                                    SERVICES
								</NavLink>
                            </div>
                            <div className="navbar-menu-item">
                                <NavLink to="/technology" className={`${location}-link`} >
                                    TECHNOLOGY
								</NavLink>
                            </div>



                            <div className="navbar-menu-item">
                                <NavLink to="/about" className={`${location}-link`} >
                                    ABOUT
								</NavLink>
                            </div>
                            <div className="navbar-menu-item">
                                <NavLink to="/contact-us" className={`${location}-link`} >
                                    CONTACT US
								</NavLink>
                            </div>
                            <div className="navbar-menu-item">
                                <NavLink to="/resources" className={`${location}-link`} >
                                    RESOURCES
								</NavLink>
                            </div>
                            <div className="navbar-menu-item">
                                <NavLink to="/locations" className={`${location}-link`} >
                                    LOCATIONS
								</NavLink>
                            </div>


                            {/* <div className='navbar-menu-item' onClick={showContactForm} style={{cursor: 'pointer'}}>
                                <div className={`${location}-link`}>CONTACT US</div>
                            </div>                            

                            <div className='more-btns'>
                                {this.renderMoreOptionsMenu()}
                                <div className='navbar-menu-item' onClick={() => this.setState({ moreOptionsOpen: true })}>
                                    <div className={`more-btn ${moreOptionsOpen ? 'up-arrow' : 'down-arrow'} ${location}-link`}>MORE</div>
                                </div>
                            </div> */}

                            <a href="https://www.titlecapture.com/app-new/account/form/quick-quote?c=trulytitle" target="_blank">
                                <div className={`btn btn-medium btn-transparent contact-us-btn`}>
                                    GET A QUOTE
                                </div>
                            </a>
                            <div className="navbarLineSeperator"></div>
                            <a href={this.titlefySignInURL()}>
                                <div className={`btn signIn`}>
                                    SIGN IN
                                </div>
                            </a>
                            <NavLink to="/signup" className='btn btn-sign-up btn-red'>
                                SIGN UP FOR FLUEID PRO
							</NavLink>

                        </div>
                        {this.renderHamburger()}
                    </div>
                    {this.renderMobileMenu()}
                </div>

                {/* Rendering for the scrolling version of the navBar */}
                <Transition visible={scrollNavBarVisible} animation="fade" duration={500}>
                    <div className="navbar-container-scrolling" id={`${mobileMenuOpen ? "hide-navbar" : ""}`}>
                        <div className="navbar-content">
                            <div className="navbar-logo-section">
                                {location === "homepage" ? <Link to="homepage-container" smooth={true} duration={750} className="logo-link scroll" /> : <NavLink to="/" className="logo-link" />}
                            </div>
                            <div className="navbar-menu-item-container">
                                <div className="navbar-menu-item">
                                    <NavLink to="/services">
                                        SERVICES
									</NavLink>
                                </div>
                                <div className="navbar-menu-item">
                                    <NavLink to="/technology">
                                        TECHNOLOGY
									</NavLink>
                                </div>


                                <div className="navbar-menu-item">
                                    <NavLink to="/about">
                                        ABOUT
									</NavLink>
                                </div>

                                <div className="navbar-menu-item">
                                    <NavLink to="/contact-us" >
                                        CONTACT US
								</NavLink>
                                </div>
                                <div className="navbar-menu-item">
                                    <NavLink to="/resources" >
                                        RESOURCES
								</NavLink>
                                </div>
                                <div className="navbar-menu-item">
                                    <NavLink to="/locations" >
                                        LOCATIONS
								</NavLink>
                                </div>
                                {/* <div className='navbar-menu-item' style={{ cursor: 'pointer' }} onClick={showContactForm}>
                                    <div className={`${location}-link`}>CONTACT US</div>
                                </div>

                                <div className='more-btns'>
                                    {this.renderMoreOptionsMenu()}
                                    <div className='navbar-menu-item' onClick={() => this.setState({ moreOptionsOpen: true })}>
                                        <div className={`more-btn ${moreOptionsOpen ? 'up-arrow' : 'down-arrow'} scrolling`}>MORE</div>
                                    </div>
                                </div> */}

                                <a href="https://www.titlecapture.com/app-new/account/form/quick-quote?c=trulytitle" target="_blank">
                                    <div className={`btn btn-medium btn-transparent`}>
                                        GET A QUOTE
                                </div>
                                </a>

                                <div className="navbarLineSeperator"></div>
                                <a href={this.titlefySignInURL()}>
                                    <div className="navbar-menu-item signIn">
                                        SIGN IN
                                    </div>
                                </a>
                                <NavLink to="/signup" className="btn btn-sign-up btn-red" onClick={openSignUpForm}>
                                    SIGN UP FLUEID PRO
								</NavLink>

                            </div>
                            {this.renderHamburger()}
                        </div>
                        {this.renderMobileMenu()}
                    </div>
                </Transition>
            </React.Fragment>
        );
    }
}

export default NavBar;
