import * as React from 'react';
import Slider from 'react-slick';
import { NavHashLink as NavLink } from 'react-router-hash-link';

const NextArrow = (props: any) => {
	const { className, style, onClick } = props;
	return (
		<div className="next-arrow" style={{}}>

		</div>
	);
};

const PrevArrow = (props: any) => {
	const { className, style, onClick } = props;
	return (
		<div className="prev-arrow" style={{}}>

		</div>
	);
};

class ServicesCarousel extends React.Component<any, any> {
	render() {
		const settings = {
			dots: true,
			infinite: true,
			speed: 750,
			nextArrow: <NextArrow />,
			prevArrow: <PrevArrow />
		};
		return (
			<div className="services-carousel-container">
				<div className="services-carousel-content">
					<Slider {...settings}>
						<div className="service-description-card">
							<div className="service-description-title">Title Insurance</div>
							<p className="service-description-paragraph">Our relationships with our underwriters will keep one of your most valuable assets secure with the highest of financial ratings. You should TRULY expect the best when dealing with us.</p>
							<NavLink to="/services#services-title-insurance" scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -64) }}>
								<p className="paragraph-end-link">More…</p>
							</NavLink>							
						</div>
						<div className="service-description-card" id="description-card-right">
							<div className="service-description-title">Closing/Escrow Services</div>
							<p className="service-description-paragraph">Escrow is a collaboration of all parties to execute a real estate transaction timely and efficiently. Our highly trained, licensed and talented staff is ready to provide TRULY exceptional service to our clients.</p>
							<NavLink to="/services#closing-services" scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -64) }}>
								<p className="paragraph-end-link">More… </p>
							</NavLink>
						</div>
					</Slider>
				</div>
			</div>
		);
	}
}

export default ServicesCarousel;