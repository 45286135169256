
import * as React from 'react';
import { Resource, uniqueID } from '../../types/resources';
import DownloadBtn from './downloadBtn';

interface Props {
    resource: Resource;
    key: string;
}

class ResourceCard extends React.Component<Props, any> {

    render() {

        const { resource } = this.props;

        return (
            <div className='resource-card'>
                <div className='doc-icon' />
                <div className='doc-title'>{resource.title}</div>
                <DownloadBtn url={resource.link} />
            </div>
        );
    }
}

export default ResourceCard;