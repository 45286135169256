import React from "react";
import ReactDOMServer from "react-dom/server";
import { Contact } from "./ContactUsPageContainter";
import { SignUpRequest } from "./signUpForm";

export function contactEmailBody(contact: Contact) {
  const body = ReactDOMServer.renderToStaticMarkup(
    <div>
      <h1>Contact Us Request</h1>
      <p>
        The following submission has been received from the Contact Us page on
        TrulyTitle.com:
      </p>
      <br></br>
      <p>
        Topic: <span>{contact.topic}</span>
      </p>
      <p>
        Name:{" "}
        <span>
          {contact.firstName} {contact.lastName}
        </span>
      </p>
      <p>
        Email: <span>{contact.email}</span>
      </p>
      <p>
        Phone: <span>{contact.phone}</span>
      </p>
      <p>
        Message: <span>{contact.message}</span>
      </p>
    </div>
  );

  return body;
}

export function signUpEmailBody(signUpRequest: SignUpRequest) {
  const body = ReactDOMServer.renderToStaticMarkup(
    <div>
      <h1>Sign Up Request</h1>
      <p>
        The following submission has been received from the Sign-Up page on
        TrulyTitle.com:
      </p>
      <br></br>
      <p>
        Name:{" "}
        <span>
          {signUpRequest.firstName} {signUpRequest.lastName}
        </span>
      </p>
      <p>
        Email: <span>{signUpRequest.email}</span>
      </p>
      <p>
        Phone: <span>{signUpRequest.phone}</span>
      </p>
      <p>
        Role: <span>{signUpRequest.role}</span>
      </p>
      <p>
        Account Manager: <span>{signUpRequest.accountManager}</span>
      </p>
      <p>
        Company Name: <span>{signUpRequest.companyName}</span>
      </p>
      <p>
        Company Type: <span>{signUpRequest.companyType}</span>
      </p>
      <p>
        Company Address: <span>{signUpRequest.companyAddress}</span>
      </p>
      <p>
        City: <span>{signUpRequest.city}</span>
      </p>
      <p>
        State: <span>{signUpRequest.state}</span>
      </p>
      <p>
        Zip Code: <span>{signUpRequest.zipCode}</span>
      </p>
    </div>
  );

  return body;
}
