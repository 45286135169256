/// <reference types="@types/googlemaps" />

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { isDev, sendToAnalytics } from "./utilities/misc";
//---------------------------------
// styles
import './App.scss';
import 'semantic-ui-css/semantic.min.css';

//---------------------------------
// components
import AboutPage from './components/AboutPage/aboutPage';
import { CookieBanner } from './components/cookieBanner';
import ContactUsPageContainter from './components/ContactUs/ContactUsPageContainter';
import HomePage from './components/HomePage/homePage';
import LocationsPage from './components/LocationsPage/locationsPage';
import PrivacyPage from './components/PrivacyTermsPages/privacyPage';
import ServicesPage from './components/ServicesPage/servicesPage';
import SignUpForm from './components/ContactUs/signUpForm';
import TechnologyPage from './components/TechnologyPage/technologyPage';
import ResourcesPage from './components/ResourcesPage/resourcesPage';
import { isLicensedState, TrulyLicensedState } from './types/officeLocations';
import GoogleMaps from './types/googleMaps';
import { getUserPosition } from './utilities/getUserPosition';

interface State {
	getUserPositionPending: boolean;
	getUserStatePending: boolean;
	signUpFormOpen: boolean;
	userPosition: google.maps.LatLngLiteral | null;
	userState: TrulyLicensedState | null;
}

class App extends Component<any, State> {

	state: State = {
		getUserPositionPending: true,
		getUserStatePending: true,
		signUpFormOpen: false,
		userPosition: null,
		userState: null,
	}

	componentDidMount() {

		// retrieve user's lat/lng data
		getUserPosition()
			.then((position) => this.setState({ userPosition: position, getUserPositionPending: false }))
			.catch((error) => {
				// be sure to turn off pending states so content will load
				this.setState({ getUserPositionPending: false, getUserStatePending: false });
				console.log(error);
			});

	}

	componentDidUpdate(prevProps: any, prevState: State) {
		const { userPosition } = this.state;

		// user position data received, call google geocode API to get user's state
		if (!prevState.userPosition && userPosition) {

			const geocoder = GoogleMaps.Geocoder();
			if (geocoder) {
				GoogleMaps.GeocodeLatLng(geocoder, userPosition)
					.then((data: google.maps.GeocoderResult) => {
						const userState = GoogleMaps.FindStateAddressComponent(data.address_components);
						this.setState({
							userState: isLicensedState(userState) ? userState : null,
							getUserStatePending: false
						});
					})
					.catch(error => {
						this.setState({ getUserStatePending: false });
						console.log(error);
					})
			} else {
				// final fallback to turn pending states off to ensure content is shown
				this.setState({ getUserStatePending: false });
			}
		}
	}

	openSignUpForm = () => {
		this.setState({ signUpFormOpen: true });
	}

	closeSignUpForm = () => {
		this.setState({ signUpFormOpen: false });
	}

	render() {

		const { signUpFormOpen, userState, userPosition, getUserPositionPending, getUserStatePending } = this.state;

		return (
			<div>
				<Router>
					<div>
						<Route
							path="/"
							render={({ location }) => {
								sendToAnalytics("event", "page_view", {
									page_location: window.location.href,
									page_path: location.pathname,
								});
								return null;
							}}
						/>
						<Switch>
							<Route path="/signup" component={SignUpForm}></Route>
							<Route path="/about" render={() => <AboutPage openSignUpForm={this.openSignUpForm} />} />
							<Route path="/technology" render={() => <TechnologyPage openSignUpForm={this.openSignUpForm} />} />
							<Route path="/services" render={() => <ServicesPage openSignUpForm={this.openSignUpForm} />} />
							<Route path="/locations" render={() => <LocationsPage openSignUpForm={this.openSignUpForm} userPosition={userPosition} getUserPositionPending={getUserPositionPending} />} />
							<Route path='/resources' render={() => <ResourcesPage openSignUpForm={this.openSignUpForm} />} />
							<Route path="/contact-us" component={ContactUsPageContainter} />
							<Route path="/privacy" render={() => <PrivacyPage openSignUpForm={this.openSignUpForm} />} />
							<Route path="/" render={() => !getUserStatePending ? <HomePage openSignUpForm={this.openSignUpForm} userState={userState} /> : <div />} />
						</Switch>
						<CookieBanner />
					</div>
				</Router>
			</div>
		);
	}
}

export default App;
