/// <reference types="@types/googlemaps" />
import * as React from 'react';
import LocationMapSection from './locationMapSection';
import NavBar from '../navBar';
import Footer from '../footer';
import { allLocations, LocationData } from '../../types/officeLocations';
import { Loader } from 'semantic-ui-react'

interface Props {
    openSignUpForm: () => void;
    userPosition: google.maps.LatLngLiteral | null;
    getUserPositionPending: boolean;
}



class LocationsPage extends React.Component<Props, any> {


    componentDidMount() {
        window.scrollTo(0, 0);
    }

    haversine_distance = (officePosition: google.maps.LatLngLiteral, userPosition: google.maps.LatLngLiteral) => {
        var R = 3958.8; // Radius of the Earth in miles
        var rlat1 = userPosition.lat * (Math.PI / 180); // Convert degrees to radians
        var rlat2 = officePosition.lat * (Math.PI / 180); // Convert degrees to radians
        var difflat = rlat2 - rlat1; // Radian difference (latitudes)
        var difflon = (officePosition.lng - (userPosition.lng)) * (Math.PI / 180); // Radian difference (longitudes)
        var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
        return d;
    }


    render() {

        const { openSignUpForm, userPosition, getUserPositionPending } = this.props;
        let data = [...allLocations];

        if (userPosition) {
            data = data.sort((a: LocationData, b: LocationData) => {
                const aDistance = this.haversine_distance(a.coordinates, userPosition);
                const bDistance = this.haversine_distance(b.coordinates, userPosition);
                return aDistance - bDistance;
            });
        }

        return (
            <div>
                <div>
                    <div className="locations-page-container">
                        <div className="navbar-hero-container">
                            <NavBar location="locations-page" openSignUpForm={openSignUpForm} />
                            <div className="locations-page-hero-container">
                                <div className="hero-text-container">
                                    <h1>Locations</h1>
                                </div>
                            </div>
                        </div>
                        <div className="locations-body-container">
                            {getUserPositionPending &&
                                <Loader active inline='centered' style={{ height: "300px", marginTop: '20%' }} />
                            }
                            {!getUserPositionPending &&
                                <div className="locations-sections-container">
                                    {
                                        data.map((item: LocationData, i: number) =>
                                            <LocationMapSection
                                                key={`location-card-${i}`}
                                                location={item}
                                            />
                                        )
                                    }
                                    <h2>We're growing quickly...more locations coming soon.</h2>
                                </div>
                            }

                        </div>
                    </div>
                    <Footer />
                </div>

            </div>

        );
    }
}

export default LocationsPage;