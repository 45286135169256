
import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';
import MapContainer from './mapContainer';
import { LocationData } from '../../types/officeLocations';

interface Props {
	location: LocationData;
}

interface State {
	elementVisible: boolean;
}

class LocationMapSection extends React.Component<Props, State> {

	state = {
		elementVisible: false
	}

	handleVisibilityUpdate = (e: any, data: any) => {

		const { elementVisible } = this.state;
		const { topVisible, bottomVisible } = data.calculations;

		if (!elementVisible && (topVisible || bottomVisible)) {
			this.setState({ elementVisible: true })
		}
	}

	render() {

		const {
			title,
			streetAddress,
			subtitle,
			cityAddress,
			tollFreeNumberVersion,
			tollFreeTrulyVersion,
			officeNumberDirect,
			faxNumber,
			locationId,
			coordinates,
			zoom,
			directionsLinkURL,
			appointmentBool,
			showMapPlaceholder
		} = this.props.location;

		const { elementVisible } = this.state;

		return (
			<Visibility fireOnMount onUpdate={this.handleVisibilityUpdate} className="visibility-location-container">
				<Transition visible={elementVisible} animation="fade up" duration={2500}>
					<div className="location-card-container">						
						<MapContainer
							locationId={locationId}
							coordinates={coordinates}
							zoom={zoom}
							mapVisible={elementVisible}
							showMapPlaceholder={showMapPlaceholder}
						/>						
						<div className="location-card" id={title === 'McKinney, Texas' ? `location-card-${title.split(" ")[0].slice(0, 8)}` : `location-card-${title.split(" ")[0]}`}>
							<div className="location-card-text">
								<div className="location-icon" />
								<h3 className="location-title">{`${title}`}</h3>
								{
									subtitle &&
									<>
										<p className="location-address first-address">{`${subtitle}`}</p>
									</>
								}

								<p className="location-address first-address">{`${streetAddress}`}</p>
								<p className="location-address" style={{ lineHeight: "5px" }}>{`${cityAddress}`}</p>
								<div className="location-contact-container">
									<div className="toll-free-numbers-container">
										<p>Toll Free: </p>
										<p>
											<a href={`tel:${tollFreeTrulyVersion}`} className="toll-free-number">{`${tollFreeTrulyVersion}`}</a>
										</p>
										<p className='toll-free-or-margin'>or</p>
										<p>
											<a href={`tel:${tollFreeTrulyVersion}`} className="toll-free-number">{`${tollFreeNumberVersion}`}</a>
										</p>
									</div>
									<div className="direct-fax-numbers">
										{
											officeNumberDirect &&
											<>
												<div className="direct-number-container">
													<p>Direct:</p>
													<p>
														<a className="direct-phone-number" href={`tel:${officeNumberDirect}`}>{`${officeNumberDirect}`}</a>
													</p>
												</div>
											</>
										}


										{officeNumberDirect && faxNumber &&
											<div className="locations-vertical-divider">|</div>

										}
										{faxNumber &&
											<p className="location-contact-numbers">{`Fax: ${faxNumber}`}</p>

										}


									</div>
									{
										appointmentBool === true ?
											<>
												<div className="direct-number-container">

													<p>
														By appointment only
													</p>
												</div>
											</> : <></>
									}
								</div>
							</div>
							{directionsLinkURL && (
								<a href={`${directionsLinkURL}`} target="_blank">
									<div className="location-directions-btn">
										DIRECTIONS
									</div>
								</a>
							)}
						</div>
					</div>
				</Transition>
			</Visibility>
		);
	}
}

export default LocationMapSection;