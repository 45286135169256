import * as React from 'react';
import NavBar from '../navBar';
import Footer from '../footer';

interface Props {
	openSignUpForm: () => void;
}

class PrivacyPage extends React.Component<Props, any> {

	constructor(props: any) {
		super(props);

	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}



	renderPrivacyContent = () => {

		const { openSignUpForm } = this.props;

		return (
			<div className="privacy-page-container">
				<NavBar location="privacy-page" openSignUpForm={openSignUpForm} />
				<div className="bottom-border">
					<div className="privacy-page-content">

						<div className="privacy-header">Privacy Policy</div>

						<p className="privacy-subheader" id="main-subheader">This Privacy Policy is effective March 1, 2019.</p>

						<p className="privacy-paragraph">This Privacy Policy identifies the information Truly Title Inc (“Truly” or “we” or “us” or “our”) gathers from and about you – the users and visitors of our TrulyTitle.com (“Website”) and mobile applications – and describes how we use the information and the steps we take to protect and maintain your information. Please take a moment to read this Privacy Policy to understand how the information we collect from you may be used.</p>

						<p className="section-header">1. Information Collection</p>
						<p className="privacy-paragraph">When you use our Website or mobile applications, we collect a variety of information from you, including personally identifiable information. This information is obtained from your online registrations, searches, applications, surveys or forms you may complete. For example, when you register to use the Website you provide us with certain personal information, such as your name, physical address, e-mail address and phone number. If you register to participate in an auction event, we may collect additional information from you, including financial and credit information, as well as details about your usage history. You may also provide information about third parties, for example, if you share property information with others via e-mail. Further, we may collect information about your transactions with our affiliates or others and may combine all information that is collected from your interactions with us, our affiliates and others.</p>
						<p className="privacy-paragraph">In addition, we may collect and store information such as your unique device identifier, the page or subject areas you visit, your browser program, Internet Protocol address, operating system and language, the state or country from which you accessed the Website or mobile application, the services you use, the information you request, the date and time of your visits, the websites you visited before and after visiting our websites, error logs, other hardware and software information, and the location of your wireless device in order to provide you location-based information. If you use any social networking functionality provided by us, we may access and use your social network profile information that you have made available to be shared.</p>

						<p className="section-header" id="cookies">Use of Cookies</p>
						<p className="privacy-paragraph">We may send "cookies" to your computer primarily to enhance your online experience. "Cookies" are files that can identify you as a unique customer and store your personal preferences as well as technical information (including click through and click stream data). Cookies can either be permanent (i.e., they remain on your computer until you delete them) or temporary (i.e., they last only until you close your browser). We may also use tracking systems such as "web beacons," which monitor your use of our websites. Tracking systems (web beacons or web bugs) are small strings of code that provide a method for delivering a graphic image on a web page for the purpose of transferring data, such as the Internet Protocol address of the computer that downloaded the page on which the web beacon appears, the Uniform Resource Locator of the page on which the web beacon appears, the time the page containing the web beacon was viewed, the types of browser that fetched the web beacon and the identification number of any cookie on the computer previously placed by that server. When corresponding with you via HTML capable e-mail, web beacons let us know whether you received and opened our e-mail.</p>
						<p className="privacy-paragraph">On their own, cookies or web beacons do not contain or reveal any personally identifiable information. However, if you choose to furnish one of our websites with personally identifiable information, this information can be linked to the data stored in the cookies/web beacons. Through your ongoing use of the Website and mobile application, you specifically agree to our described use of cookies and web beacons. You may adjust the privacy and security settings of your browser or mobile device to reject cookies from us or from any other site. Additionally, by setting your web browser to display HTML e-mails as text only, you may be able to prevent the use of some web beacons. Please consult the "Help" section of your browser for more information, or consult the instructions set forth by your mobile service provider or mobile device manufacturer. However, certain areas of our websites can only be accessed in conjunction with cookies or similar devices and you should be aware that disabling cookies or similar devices might prevent you from accessing some of our content.</p>

						<p className="section-header">2. Information Use and Disclosure</p>
						<p className="privacy-paragraph">Unless you choose to opt-out, by using the Website or mobile applications you consent that we may use or share information we have about you to enhance your user experience, determine your eligibility for a wider range of services or products available to you and provide you notification of same, customize and personalize the content provided to you on the Website or mobile applications, send you information about topics that may be of interest to you, perform research and analytics, and provide you communications related to your account. Further, we may share your information with others in the following ways:</p>
						<p className="privacy-subheader">With Affiliates of Truly Title, Inc.</p>
						<p className="privacy-paragraph smaller-margin">The Fair Credit Reporting Act gives you the opportunity to limit the sharing of "non-experience information" among affiliates of Truly. "Non-experience information" includes information that is used, expected to be used, or collected for the purpose of establishing your eligibility for consumer credit, insurance or deposit accounts, and is not related solely to your transactions or other experiences with us, including information:</p>
						<ul className="info-use-list">
							<li>From your applications</li>
							<li>From a credit report, such as your credit history</li>
							<li>From sources used to verify representations made by you, such as your open lines of credit or employment history</li>
						</ul>

						<p className="privacy-subheader">Within Truly's family of companies</p>
						<p className="privacy-paragraph">We may share the customer information we collect with affiliate companies providing financial, title/escrow/closing and other settlement services companies, inspection companies, home security companies, and pest control companies.</p>
						<p className="privacy-paragraph">If you prefer that we not share non-experience information about you with an affiliate of Truly, you may opt out. That is, you may direct us not to share this information by calling us toll-free at (888) 488-7859 or by making a written request to us at 2901 N Dallas Parkway, Suite 130, Plano, Texas 75093, Attn: Chief Privacy Officer, privacy@trulytitle.com. The Fair Credit Reporting Act also gives you the option of limiting our affiliates' use of your information that we share with them.</p>

						<p className="privacy-subheader">With Third Party Service Providers, Joint Marketers and As Otherwise Permitted by Law</p>
						<p className="privacy-paragraph smaller-margin">We may work with non-affiliated companies or organizations to provide you with opportunities to buy products or services offered by us or jointly with other companies. Consequently, we may disclose some or all of the information that we collect to the following:</p>
						<ul className="info-use-list">
							<li>Companies that perform services on our behalf, such as data processing companies, customer/support services and other vendors</li>
							<li>Companies with whom we have joint marketing agreements</li>
						</ul>

						<p className="privacy-paragraph">For your protection, we require that these companies keep all personal information secure and confidential.</p>
						<p className="privacy-paragraph">There may be additional times when it is necessary for us to use and share your information, and we may disclose some or all of the information that we collect as permitted by law.</p>

						<p className="privacy-paragraph smaller-margin">We may also disclose your personal information:</p>
						<ul className="info-use-list">
							<li>
								To comply with any court order, law or legal process, including responding to any government or regulatory request
							</li>
							<li>
								To enforce or apply our Terms of Use and other agreements
							</li>
							<li>
								To support compliance or audit requirements
							</li>
							<li>
								To detect, prevent or otherwise respond to fraud, security or technical concerns
							</li>
							<li>
								If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Website, our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction
							</li>
							<li>
								In the event of a merger, acquisition or other transfer involving all or part of our business
							</li>
						</ul>

						<p className="privacy-subheader">With Other Third Parties</p>
						<p className="privacy-paragraph smaller-margin">We may disclose some or all of the information that we collect with non-affiliated companies or other organizations, including:</p>
						<ul className="info-use-list">
							<li>
								Title/escrow/closing companies and insurance agents
							</li>
							<li>
								Other companies and organizations, including non-profit organizations
							</li>
						</ul>

						<p className="section-header">3. Targeted Advertising</p>
						<p className="privacy-paragraph">We may also use technologies to collect information about your online activities over time and across third party websites or other online services (behavioral tracking). We believe that this online behavioral tracking helps to provide you with more relevant advertising based upon on the websites you visit. Third parties may also use similar technologies to collect information about your online activities over time and across different third-party sites, including your use of this Website.</p>
						<p className="privacy-paragraph">You can visit the NAI Opt-Out web site for information on how you can opt out of behavioral tracking on this Website. Using the choice mechanisms these programs make available may help you see advertising that is relevant to you or help you avoid seeing interest-based advertising generally. Please note that the Website does not respond to or honor "Do Not Track" requests. We also use cookies to improve the performance of our advertising on other websites. Although you may not opt out of receiving online advertisements generally, you may control the collection of data on our site used for targeted Website advertising during your visits to other websites.</p>

						<p className="section-header">4. How We Protect Your Information</p>
						<p className="privacy-paragraph">Keeping your personal information secure is one of our most important responsibilities. Only those persons who need it to perform their job responsibilities are authorized to access your information. We take commercially reasonable precautions to protect your information and limit disclosure by maintaining physical, electronic and procedural safeguards.</p>

						<p className="section-header">5. Maintenance of Accurate Information</p>
						<p className="privacy-paragraph">We have established commercially reasonable procedures designed to ensure that your personal information is as accurate and complete as possible. If you believe that our records contain inaccurate or incomplete information about you, please notify us by calling (888) 488-7859 or by making a written request to us at 2901 N Dallas Parkway, Suite 130, Plano, Texas 75093, Attn: Chief Privacy Officer, privacy@trulytitle.com. We will take commercially reasonable steps to investigate your concerns and correct inaccurate information in a timely manner.</p>

						<p className="section-header">6. Other Websites</p>
						<p className="privacy-paragraph">The Website may contain hyperlinks to other Internet sites. While we try to link only to sites that share our high standards and respect for privacy, we are not responsible for the content or the privacy practices employed by other sites. These other websites may collect and share data in a manner that is not consistent with our privacy practices. The Website may also contain hyperlinks to the Internet sites of Truly Title Inc. affiliates. This Privacy Policy applies only to the Website. The privacy policy of Truly affiliates may differ from this Privacy Policy, and any activity conducted on an affiliate's website is governed by the privacy policy of that affiliate. We encourage you to review the privacy policy of any website you may visit via hyperlink from the Website to ensure that you agree with the terms of that policy prior to using the site.</p>

						<p className="section-header">7. Changes to this Privacy Policy</p>
						<p className="privacy-paragraph">We may revise this Privacy Policy from time to time as we deem necessary. Any changes to the Privacy Policy will be posted on this webpage and take immediate effect. Please review this webpage on a regular basis to remain informed about the most recent terms of our Privacy Policy.</p>

						<p className="section-header">8. Acceptance of Privacy Policy Terms</p>
						<p className="privacy-paragraph">Your ongoing use of the Website constitutes your acceptance of this Privacy Policy, including any changes to the Privacy Policy following the posting of such changes. If you do not agree with this Privacy Policy, please do not use this Website.</p>

						<p className="section-header">9. Children Under 13 Years of Age</p>
						<p className="privacy-paragraph">Pursuant to the Website Terms of Use, use of the Website by children under the age of 13 is prohibited. Consistent with this prohibition we will not purposely or intentionally collect any information of any child under the age of 13.</p>


					</div>
				</div>
				<Footer />
			</div>
		);
	}

	render() {


		return (
			<div>
				{
					this.renderPrivacyContent()

				}
			</div>

		);
	}

}

export default PrivacyPage;